import { useDispatch } from "react-redux";
import { useHandshakeMutation } from "../../redux";
import { setAccessToken } from "../../redux/auth";

const useVerifyImpersonationHandshake = () => {
    const dispatch = useDispatch();
	const [_verify, { isLoading, ...verifyResult }] = useHandshakeMutation();

	const verify = (sessionId, code) => {
		return new Promise((resolve, reject) => {
			return _verify({ sessionId, code })
				.unwrap()
				.then(
					r => { 
						const { accessToken, user } = r;
                        if (!accessToken || !user) {
                            window.location.href = '/signin';
                            return reject({ message: 'Invalid token details.' });
                        }

						dispatch(setAccessToken({ accessToken, user }));
						window.location.href = '/dashboard';
						return resolve(r);
					},
					e => { 
						return reject(e);
					}
				)
		});	
	};

	return { verify, isLoading, data: { isLoading, ...verifyResult } };
};

export default useVerifyImpersonationHandshake;