import { useSendPasswordResetRequestMutation } from "../../redux";

const useResetPasswordRequest = () => {
    const [_reset, { isLoading, ...resetData }] = useSendPasswordResetRequestMutation();

    const reset = (emailAddress) => {
        return new Promise((resolve, reject) => {
            return _reset({ emailAddress })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { reset, isLoading, data: { isLoading, ...resetData } };
};

export default useResetPasswordRequest;