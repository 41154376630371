import React from "react";
import Skeleton from "react-loading-skeleton";

export const StandardTableCreatingRow = ({ columns, count = 1, height = 37, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            <Skeleton count={count} height={height} />
        </td>
    </tr>
};

export const StandardTableEmptyRow = ({ columns, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>Your search did not yield any results.</td>
    </tr>
};

export const StandardTableErrorRow = ({ columns, error, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            <span>TODO: add error table row here</span>
            {/* <ErrorMessage error={error} /> */}
        </td>
    </tr>
};

export const StandardTableLoadingRow = ({ columns, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>Loading...</td>
    </tr>
};

export const StandardTableRow = ({ columns, data, ...rest }) => {
    const renderColumn = (col, index) => {
        if (col.renderer && typeof(col.renderer) === 'function') {
            return col.renderer({ ...col, data, key: `row-col-${index}` });
        }
        return (
            <td key={`row-col-${index}`}>
                {data[col.property]}
            </td>
        );
    };

    return <tr {...rest}>
        {columns.map((col, colIndex) => renderColumn(col, colIndex))}
    </tr>
};

const StandardTable = ({
    className = "",
    columns = [], 
    CreatingRowComponent = StandardTableCreatingRow,
    creatingRowComponentProps = {},
    data = [], 
    EmptyRowComponent = StandardTableEmptyRow,
    emptyRowComponentProps = {},
    error = null,
    ErrorRowComponent = StandardTableErrorRow,
    errorRowComponentProps = {},
    hideHeader = false,
    hideHeaderOnEmpty = false,
    LoadingRowComponent = StandardTableLoadingRow,
    loadingRowComponentProps = {},
    isCreating = false,
    isLoading = false,
    RowComponent = StandardTableRow,
    rowComponentProps = {},
    ...rest
}) => {
    const canRenderHeader = () => {
        if (hideHeader === true) {
            return false;
        }
        if ((error || data.length === 0) && hideHeaderOnEmpty === true) {
            return false;
        }
        return true;
    };

    const renderColumnHeader = (col, index) => {
        if (col.headerRenderer && typeof(col.headerRenderer) === 'function') {
            return col.headerRenderer({...col, key: `col-${index}`})
        }
        return (
            <th key={`col-${index}`} className={col.headerClassName ? col.headerClassName : ''}>
                {col.label}
            </th>
        );
    };

    return (
        <div className="overflow-x-auto">
            <table className={`table-auto w-full text-left text-sm border-separate border-spacing-4 ${className}`}>
                {canRenderHeader() === true && (
                    <thead>
                        <tr>
                            {columns.map((el, i) => renderColumnHeader(el, i))}
                        </tr>
                    </thead>
                )}
                <tbody>
                    {isLoading === true && <LoadingRowComponent columns={columns} {...loadingRowComponentProps} />}
                    {isLoading === false && error && <ErrorRowComponent columns={columns} error={error} {...errorRowComponentProps} />}
                    {isLoading === false && !error && data.length === 0 && <EmptyRowComponent columns={columns} {...emptyRowComponentProps} />}
                    {isLoading === false && !error && data.length > 0 && data.map((rec, rowIndex) => <RowComponent key={`row-${rowIndex}`} columns={columns} data={rec} {...rowComponentProps} />)}
                    {isLoading === false && isCreating === true && <CreatingRowComponent columns={columns} count={1} height={37} {...creatingRowComponentProps} />}
                </tbody>
            </table>
        </div>
    );
};

export default StandardTable;