import React from "react";
import usePortfolioSummary from "./hooks/usePortfolioSummary";
import { DealingAccountsBalanceDisplayCard, TotalPortfolioBalanceDisplayCard } from "./components/BalanceDisplayCard";
import { InvestmentProductsTableDisplayCard, InvestmentOwnershipTableDisplayCard } from "./components/TableDisplayCard";

const PortfolioSummary = () => {
    const { data, error, isFetching, isLoading } = usePortfolioSummary();
    
    return (
        <div className="grid gap-2 grid-cols-1 md:grid-cols-2 md:gap-4">
            <TotalPortfolioBalanceDisplayCard 
                data={data} 
                error={error} 
                isLoading={isFetching || isLoading} 
            />
            <DealingAccountsBalanceDisplayCard 
                data={data} 
                error={error} 
                isLoading={isFetching || isLoading} 
            />
            <InvestmentProductsTableDisplayCard 
                data={data}
                error={error} 
                isLoading={isFetching || isLoading} 
            />
            <InvestmentOwnershipTableDisplayCard 
                data={data} 
                error={error} 
                isLoading={isFetching || isLoading} 
            />
        </div>
    );
};

export default PortfolioSummary;