import React, { useMemo, useState } from "react";
import PortfolioPerformanceViewSelector from "./components/PortfolioPerformanceViewSelector";
import HistoricalTotalReturnSummary from "./components/HistoricalTotalReturnSummary";
import HistoricalTotalReturnByVolatilitySummary from "./components/HistoricalTotalReturnByVolatilitySummary";
import HistoricalVolatilityStatistics from "./components/HistoricalVolatilityStatistics";
import PortfolioPerformanceSummary from "./components/PortfolioPerformanceSummary"
import PortfolioPerformanceSummaryByProduct from "./components/PortfolioPerformanceSummaryByProduct"

const PortfolioPerformanceViews = [
    { id: 1, name: "Historical Total Return Summary" },
    { id: 2, name: "Historical Total Return by Volatility" },
    { id: 3, name: "Historical Volatility Statistics" },
    { id: 4, name: "Your Portfolio Summary" },
    { id: 5, name: "Your Portfolio by Product" }
];

const PortfolioPerformance = () => {

    const [currentTab, setCurrentTab] = useState(PortfolioPerformanceViews[0]);
    const featureDisplay = useMemo(() => {
        if (!currentTab || !currentTab.id) {
            return null;
        }

        switch(currentTab.id) {
            case 1:
                return <HistoricalTotalReturnSummary />;
            case 2:
                return <HistoricalTotalReturnByVolatilitySummary />;
            case 3:
                return <HistoricalVolatilityStatistics />;
            case 4:
                return <PortfolioPerformanceSummary />;
            case 5:
                return <PortfolioPerformanceSummaryByProduct />;
            default:
                return null;
        }
    }, [currentTab]);

    return (
        <React.Fragment>
            <PortfolioPerformanceViewSelector currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <div className="my-3">
                {featureDisplay}
            </div>
        </React.Fragment>
    )
}

export default PortfolioPerformance;