import { useGetProductSummaryQuery } from "../../../redux";

const usePerformanceProductSummary = () => {

    const data = useGetProductSummaryQuery();

    return {
        ...data
    };
};

export default usePerformanceProductSummary;