import React from "react";

export const DisplayCardBody = ({ className, children, ...rest }) => {
    return (
        <div className={`flex flex-col flex-1 gap-1 p-8 ${className ? className : ''}`} {...rest}>
            {children}
        </div>
    );
};

const DisplayCard = ({ className, children, ...rest }) => {
    return (
        <div className={`flex flex-col border-2 rounded-md ${className ? className : ''}`} {...rest}>
            {children}
        </div>
    );
};

export default DisplayCard;