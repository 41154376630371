/// Generic Card for Displaying Total Portfolio Value & Dealing Accounts Balance
import React from "react";
import Moment from "react-moment";
import Money from "../../../components/Money";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import DisplayCard, { DisplayCardBody } from "../../../components/DisplayCard";

export const DealingAccountsBalanceDisplayCard = ({ data = null, error = null, isLoading = false }) => {
    const { dealingsAccountBalanceValueFloored, dealingsAccountBalanceValueDateUtc, portfolioCurrencySymbol } = data || { dealingsAccountBalanceValueFloored: 0, portfodealingsAccountBalanceValueDateUtclioValueDateUtc: new Date(), portfolioCurrencySymbol: "£" };
    return (
        <BalanceDisplayCard 
            cardBackground="bg-white" 
            cardColor="text-green-500"
            currencySymbol={portfolioCurrencySymbol}
            error={error}
            hasData={data != null}
            isLoading={isLoading}
            title="Dealing Accounts Balance"
            value={dealingsAccountBalanceValueFloored}
            valueDate={dealingsAccountBalanceValueDateUtc}
        />
    );
};

export const TotalPortfolioBalanceDisplayCard = ({ data = null, error = null, isLoading = false }) => {
    const { totalPortfolioValue, portfolioValueDateUtc, portfolioCurrencySymbol } = data || { totalPortfolioValue: 0, portfolioValueDateUtc: new Date(), portfolioCurrencySymbol: "£" };
    return (
        <BalanceDisplayCard 
            cardBackground="bg-white" 
            cardColor="text-violet-500"
            currencySymbol={portfolioCurrencySymbol}
            error={error}
            hasData={data != null}
            isLoading={isLoading}
            title="Total Portfolio Value"
            value={totalPortfolioValue}
            valueDate={portfolioValueDateUtc}
        />
    );
};

const BalanceDisplayCard = ({ 
    cardBackground = "bg-white", 
    cardColor = "text-black", 
    currencySymbol = "£",
    error = null, 
    hasData = false,
    isLoading = false, 
    title = "",
    value = 0, 
    valueDate = new Date() 
}) => {
    return (
        <DisplayCard className={`w-full shadow-inner border-slate-300 ${cardBackground} ${cardColor} ${isLoading === true ? 'animate-pulse h-48' : ''}`}>
            {isLoading === false && (
                <DisplayCardBody className="text-center">
                    <h2 className="card-title justify-center text-xl uppercase">{title}</h2>
                    {hasData === true && !error && (
                        <React.Fragment>
                            <p className="text-5xl tabular-nums my-3">
                                <Money prefix={currencySymbol} value={value} />
                            </p>
                            <p className="text-sm">as at <Moment date={valueDate} format="Do MMMM YYYY" /></p>
                        </React.Fragment>
                    )}
                    {(hasData === false || error) && (
                        <React.Fragment>
                            <FetchErrorMessage />
                        </React.Fragment>
                    )}
                </DisplayCardBody>
            )}
        </DisplayCard>
    );
};

export default BalanceDisplayCard;