import React from "react";

export const GreenBadge = ({ children, ...rest }) => {
    return (
        <Badge bgColour="bg-green-100" textColour="text-green-800" {...rest}>
            {children}
        </Badge>
    );
};

export const RedBadge = ({ children, ...rest }) => {
    return (
        <Badge bgColour="bg-red-100" textColour="text-red-800" {...rest}>
            {children}
        </Badge>
    );
};

export const YellowBadge = ({ children, ...rest }) => {
    return (
        <Badge bgColour="bg-yellow-100" textColour="text-yellow-800" {...rest}>
            {children}
        </Badge>
    );
};

const Badge = ({ children, className, bgColour = "bg-blue-100", textColour = "text-blue-800", ...rest }) => {
    return (
        <div {...rest}>
            <span className={`text-sm font-medium mr-2 px-2.5 py-0.5 rounded ${bgColour} ${textColour} ${className ? className : ''}`}>
                {children}
            </span>
        </div>
    );
};

export default Badge;