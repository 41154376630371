import React from "react";
import { StaticAccordion } from "../../../components/Accordion";
import usePortfolioInvestmentsByShareClass from "../hooks/usePortfolioInvestmentsByShareClass";
import Money from "../../../components/Money";
import LoadingSpinner from "./LoadingSpinner";
import LoadingSkeleton from "./LoadingSkeleton";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import PortfolioValuationInvestmentSummaryWrapper from "./PortfolioValuationInvestmentsSummaryWrapper";

const PortfolioValuationInvestmentSummaryByShareClass = () => {

    const { data, error, isFetching, isLoading } = usePortfolioInvestmentsByShareClass();

    if(isLoading || isFetching){
        return(
            <PortfolioValuationInvestmentSummaryWrapper>
                <LoadingSkeleton />
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(error){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full text-center">
                    <FetchErrorMessage />
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    return( <PortfolioValuationInvestmentSummaryWrapper title={data.description} value={data.totalFundValues} allocation={data.totalAllocation}>
            <table className="w-full table-fixed text-zinc-600 text-xs lg:text-base">
                        <thead>
                            <tr className="text-blue-900">
                            <th className="md:w-[70%]"></th>
                                <th className="text-right">Value</th>
                                <th className="text-right">Allocation</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-x-auto">
                            {data != null && data.investments.length > 0 && data.investments.map((el, index) => (
                                <tr key={index}>
                                    <td className="truncate">{el.investmentName}</td>
                                    <td className="text-right tabular-nums"> <Money prefix={'£'} value={el.value} showNil={false} /></td>
                                    <td className="text-right tabular-nums">{el.allocation}%</td>
                                </tr>
                            ))}

                            {data == null || data.investments.length <= 0 && 
                                <tr>
                                <td>There are no investments to show.</td>
                                </tr>
                            }             
                        </tbody>
                    </table>
        </PortfolioValuationInvestmentSummaryWrapper>
    );
}

export default PortfolioValuationInvestmentSummaryByShareClass;