import { useVerifyPasswordResetCodeMutation } from "../../redux";

const useVerifyPasswordResetCode = () => {
    const [_verify, { isLoading, ...verifyData }] = useVerifyPasswordResetCodeMutation();

    const verify = (emailAddress, resetCode) => {
        return new Promise((resolve, reject) => {
            return _verify({ emailAddress, resetCode })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { verify, isLoading, data: { isLoading, ...verifyData } };
};

export default useVerifyPasswordResetCode;