import React from "react";
import Money from "../../../components/Money";
import usePortfolioGain from "../hooks/usePortfolioGain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Percentage from "../../../components/Percentage";


const TotalGainDisplay = () => {
    
    const { data, error, isFetching, isLoading } = usePortfolioGain();
    
    return <table className="flex-1">
                <thead>
                    <tr className="text-lg lg:text-2xl w-[100%] font-bold text-gray-600">
                        {isLoading && !error && <th className="animate-pulse">Total Gain {" "} : £...</th>}
                        {error &&  <th><FontAwesomeIcon  icon="fa-triangle-exclamation" /></th>}
                        {!isLoading && !isFetching && !error && data!= null && <th>Total Gain {" "} : <Money prefix={'£'} value={data.totalGain} showNil={false} /> ({<Percentage value={data.totalGainPercent} decimalPlaces={1} />})</th>}
                    </tr>
                </thead>
            </table>
};

export default TotalGainDisplay;
