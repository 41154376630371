import React from "react";
import usePortfolioInvestmentsByVolatility from "../hooks/usePortfolioInvestmentsByVolatility";
import Money from "../../../components/Money";
import LoadingSkeleton from "./LoadingSkeleton";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import PortfolioValuationInvestmentSummaryWrapper from "./PortfolioValuationInvestmentsSummaryWrapper";

const PortfolioValuationInvestmentSummaryByVolatility = () => {

    const { data, error, isFetching, isLoading } = usePortfolioInvestmentsByVolatility();

    if(isLoading || isFetching){
        return(
            <PortfolioValuationInvestmentSummaryWrapper>
                <LoadingSkeleton />
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(!error && data == null || data.length <= 0){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full">
                    <table className="w-full table-fixed p-6 text-zinc-600">
                        <thead>
                            <tr className="text-blue-900">
                                <th className="md:w-[70%]"></th>
                                <th className="text-right">Value</th>
                                <th className="text-right">Allocation</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-x-auto">
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(error){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full text-center">
                    <FetchErrorMessage />
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }
    

    return (
        <>
         {data != null && data.length > 0 && data.map((el, i) => (
                <div key={i} className="mb-3">
                    <PortfolioValuationInvestmentSummaryWrapper type="accordion" title={el.description} value={el.totalFundValues} allocation={el.totalAllocation}>
                        <table className="w-full table-fixed p-6 text-zinc-600 text-xs lg:text-base">
                            <thead>
                                <tr className="text-blue-900">
                                <th className="md:w-[70%]"></th>
                                    <th className="text-right">Value</th>
                                    <th className="text-right">Allocation</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-x-auto">
                                {el.subGroupings.length > 0 && el.subGroupings.map((c) => (
                                    <>
                                        <tr className="font-bold">
                                            <th colspan="3" className="md:w-[70%] truncate">{c.description}</th>
                                        </tr>

                                        {c.investments.length > 0 && c.investments.map((inv, index) => (
                                            <tr key={index}>
                                                <td className="truncate">{inv.investmentName}</td>
                                                <td className="text-right tabular-nums"> <Money prefix={'£'} value={inv.value} showNil={false} /></td>
                                                <td className="text-right tabular-nums">{inv.allocation}%</td>
                                            </tr>
                                        ))}

                                        <tr className="font-bold">
                                            <th ></th>
                                            <th className="text-right tabular-nums"><Money prefix={'£'} value={c.totalFundValues} showNil={false} /></th>
                                            <th className="text-right tabular-nums">{c.totalAllocation}%</th>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </PortfolioValuationInvestmentSummaryWrapper>
                </div>
            ))}
        </>
    )
}

export default PortfolioValuationInvestmentSummaryByVolatility;