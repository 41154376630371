import _ from "lodash";
import React from "react";
import useWebsiteNavigation from "./WebsiteNavigationContext";
import { useSession } from "./SessionContext";
import { Navigate, useParams } from "react-router-dom";
import { LoadingPage } from "../pages";

export const DocumentSlugRouteMappings = {
    "business-sheets": "business_sheet_access",
    "advice-reports": "advice_report_access",
    "tax-packs": "tax_pack_access",
    "contract-notes": "contract_notes_access",
    "custody-statements": "custody_statements_access",
    "cost-disclosures": "cost_disclosures_access",
};

export const DocumentSlugRouteAuthRoleGuard = ({ children, unauthorisedUrl = "/unauthorised" }) => {
    const auth = useSession();
    const { slug } = useParams();
    const { user: { roles } } = auth;

    if (!slug || !DocumentSlugRouteMappings[slug] || !roles || !roles.includes(DocumentSlugRouteMappings[slug])) {
        return <Navigate to={`${unauthorisedUrl}?redirect=${encodeURIComponent(window.location.href)}`} replace={true} />
    }

    return children;
};

export const RouteAuthRoleGuard = ({ children, unauthorisedUrl = "/unauthorised" }) => {
    const auth = useSession();
    const { getMenuItemByPathname } = useWebsiteNavigation();
    const { user: { roles } } = auth;

    const hasRoutePermission = () => {
        const menuItem = getMenuItemByPathname(window.location.pathname);
        if (!menuItem || (menuItem && !menuItem.requiredRoles) || (menuItem && menuItem.requiredRoles && menuItem.requiredRoles.length === 0)) {
            return true;
        }

        const menuItemRoles = menuItem.requiredRoles;
        return _.some(roles, item => menuItemRoles.includes(item));
    };

    if (!hasRoutePermission()) {
        return <Navigate to={`${unauthorisedUrl}?redirect=${encodeURIComponent(window.location.href)}`} replace={true} />
    }

    return children;
};

export const RouteSwitchGuard = ({ route, redirect, requiredRoles = [] }) => {
    const auth = useSession();
    const { user: { roles } } = auth;

    return !_.some(roles, item => requiredRoles.includes(item))
        ? <Navigate to={redirect} replace={true} />
        : <Navigate to={route} replace={true} />
};

const ProtectedRoute = ({ children, loginUrl = "/signin", loadOverride = false }) => {
    const auth = useSession();
    const { isAuthenticated, isLoading, isUninitialized } = auth;

    if ((isUninitialized && isLoading) || loadOverride === true) {
        return <LoadingPage />
    }

    if (!isAuthenticated) {
        return <Navigate to={loginUrl} replace={true} />
    }

    return children;
};

export default ProtectedRoute;