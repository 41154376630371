import React from "react";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import PortfolioProfile from "../features/portfolioprofile";

const PortfolioProfilePage = () => {
    return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader>Portfolio Profile</WebsitePageHeader>
                <PortfolioProfile />
            </div>
        </React.Fragment>
    );
};

export default PortfolioProfilePage;
