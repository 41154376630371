import React, { useState } from "react";

const FundChartDisplay = ({ chartData }) => {

    return (
        <div className="pb-2 mt-2 bg-white">
            <div className="w-full p-1 text-center bg-fuchsia-700 text-white text-bold">
                <h2 className="text-md">Historical Performance</h2>
            </div>
            <img className="m-auto h-auto mb-2 p-1" src={`data:image/jpeg;base64,${chartData}`} />
        </div>
    );
};

export default FundChartDisplay;
