import { useGetPortfolioWealthQuery } from "../../../redux";

const usePortfolioWealth = () => {
    
    const data = useGetPortfolioWealthQuery();

    return {
        ...data
    };
};

export default usePortfolioWealth;