import wealthProApi from "../api";

const changesEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        getPortfolioChangesCount: builder.query({
            query: () => 'api/portfoliochanges/count'
        }),
        getPortfolioChanges: builder.query({
            query: () => 'api/portfoliochanges'
        }),
       
    })
});

export const {
    useLazyGetPortfolioChangesCountQuery,
    useLazyGetPortfolioChangesQuery,
    useGetPortfolioChangesQuery
} = changesEndpoints;