import React from "react";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import PortfolioPerformance from "../features/portfolioperformance";

const PortfolioPerformancePage = () => {
    return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader>Portfolio Performance</WebsitePageHeader>
                <PortfolioPerformance />
            </div>
        </React.Fragment>
    );
};

export default PortfolioPerformancePage;
