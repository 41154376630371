import React from "react";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import DisplayCard, { DisplayCardBody } from "../../../components/DisplayCard";

const ProfileComment = ({ data = null, error = null, isLoading = false }) => {
    
    return (
        <DisplayCard className={` w-full shadow-md bg-white border border-slate-300 rounded ${isLoading === true ? 'animate-pulse h-24' : ''}`}>
            {isLoading === false && (
                <DisplayCardBody>
                    {data && !error && (
                        <span className="prose max-w-none text-zinc-600 text-zinc-600 text-xs lg:text-base" dangerouslySetInnerHTML={{
                            __html: data
                          }} ></span>
                    )}
                    {((!data || data == null || data === "") && !error) && (
                        <div>
                            <p>The adviser has not left a portfolio comment yet.</p>
                        </div>
                    )}
                    {(error) && (
                        <div className="text-center">
                            <FetchErrorMessage />
                        </div>
                    )}
                </DisplayCardBody>
            )}
        </DisplayCard>
    );
};

export default ProfileComment;