import { useGetInvestmentsByProductSummaryQuery } from "../../../redux";

const usePortfolioInvestmentsByProduct = () => {
    
    const data = useGetInvestmentsByProductSummaryQuery();

    return {
       ...data
    };
};

export default usePortfolioInvestmentsByProduct;