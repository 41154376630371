import React from "react";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import FundCommentary from "../features/fundcommentary";

const FundCommentaryPage = () => {
    return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader>Fund Commentary</WebsitePageHeader>
                <FundCommentary />
            </div>
        </React.Fragment>
    );
};

export default FundCommentaryPage;
