import _ from "lodash";
import React from "react";
import usePortfolioChanges from "./PortfolioChangesContext";
import { useSession } from "./SessionContext";

const WebsiteNavigationContext = React.createContext();

const useWebsiteNavigation = () => React.useContext(WebsiteNavigationContext);

export const WebsiteNavigationProvider = ({ children, baseUrl = "/dashboard" }) => {
    const auth = useSession();
    const { user: { roles } } = auth;
    const { changesCount } = usePortfolioChanges();

    const sitemap = [
        {
            "title": "Portfolio",
            "menu": [
                {
                    "to": `${baseUrl}/portfolio/summary`,
                    "title": "Summary",
                    "text": "View a summary of your current portfolio & wealth.",
                    "icon": "fa-th-large",
                    "iconClass": "text-green-300",
                    "requiredRoles": ["portfolio_access"]
                },
                {
                    "to": `${baseUrl}/portfolio/profile`,
                    "title": "Portfolio Profile",
                    "text": "View our current comments on your portfolio and see it broken down into Diversification & Volatility.",
                    "icon": "fa-chart-pie",
                    "iconClass": "text-red-300",
                    "requiredRoles": ["portfolio_access"]
                },
                {
                    "to": `${baseUrl}/portfolio/valuation`,
                    "title": "Valuation",
                    "text": "View a breakdown of all your funds.",
                    "icon": "fa-sterling-sign",
                    "iconClass": "text-purple-300",
                    "requiredRoles": ["valuations_access"]
                },
                {
                    "to": `${baseUrl}/portfolio/performance`,
                    "title": "Performance",
                    "text": "See how well your funds previously and currently perform.",
                    "icon": "fa-chart-line",
                    "iconClass": "text-blue-300",
                    "requiredRoles": ["valuations_access"]
                },
                {
                    "to": `${baseUrl}/portfolio/fund-commentary`,
                    "title": "Fund Commentary",
                    "text": "View our in-house commentary on how well your funds are currently doing.",
                    "icon": "fa-comment-alt",
                    "iconClass": "text-orange-300",
                    "requiredRoles": ["valuations_access"]
                },
                {
                    "to": `${baseUrl}/portfolio/changes`,
                    "title": "Changes",
                    "text": "View a summary of your any outstanding changes to your portfolio.",
                    "icon": "fa-gears",
                    "iconClass": "text-slate-300",
                    "requiredRoles": ["portfolio_access"]
                }
            ]
        },
        {
            "title": "Documents",
            "menu": [
                {
                    "to": `${baseUrl}/documents/business-sheets`,
                    "title": "Business Sheets",
                    "text": "View your past and current business sheets.",
                    "icon": "fa-file-pdf",
                    "iconClass": "text-green-300",
                    "requiredRoles": ["business_sheet_access"]
                },
                {
                    "to": `${baseUrl}/documents/tax-packs`,
                    "title": "Tax Packs",
                    "text": "View your yearly tax packs.",
                    "icon": "fa-file-pdf",
                    "iconClass": "text-purple-300",
                    "requiredRoles": ["tax_pack_access"]
                },
                {
                    "to": `${baseUrl}/documents/contract-notes`,
                    "title": "Contract Notes",
                    "text": "View your current contract notes.",
                    "icon": "fa-file-pdf",
                    "iconClass": "text-blue-300",
                    "requiredRoles": ["contract_notes_access"]
                },
                {
                    "to": `${baseUrl}/documents/custody-statements`,
                    "title": "Custody Statements",
                    "text": "View your quarterly custody statements.",
                    "icon": "fa-file-pdf",
                    "iconClass": "text-orange-300",
                    "requiredRoles": ["custody_statements_access"]
                },
                {
                    "to": `${baseUrl}/documents/cost-disclosures`,
                    "title": "Cost Disclosures",
                    "text": "View your quarterly cost disclosures.",
                    "icon": "fa-file-pdf",
                    "iconClass": "text-fuchsia-300",
                    "requiredRoles": ["cost_disclosures_access"]
                }
            ]
        },
        {
            "title": "Investment Perspectives",
            "menu": [
                {
                    "to": `${baseUrl}/our-view`,
                    "title": "Our View",
                    "text": "Read our opinions about the current market.",
                    "icon": "fa-globe",
                    "iconClass": "text-red-300"
                }
            ]
        }
    ];

    const mySiteMap = React.useMemo(() => {
        const map = [];
        
        for (var i = 0; i < sitemap.length; i++) {
            var subMap = sitemap[i].menu.filter(({ requiredRoles, to }) => !requiredRoles || requiredRoles.length === 0 || _.some(roles, item => requiredRoles.includes(item)) && (to != `${baseUrl}/portfolio/changes` || changesCount > 0));
            if (subMap.length > 0) {
                map.push({ ...sitemap[i], menu: subMap });
            }
        }

        return map;
    }, [ roles, sitemap ]);

    const mySiteMapFlat = React.useMemo(() => {
        if (!mySiteMap) {
            return [];
        }

        const map = [];
        for (var i = 0; i < sitemap.length; i++) {
            for (var j = 0; j < sitemap[i]['menu'].length; j++) {
                map.push(sitemap[i]['menu'][j]);
            }
        }
        return map;
    }, [ mySiteMap ]);

    const getMenuItemByPathname = (pathname) => mySiteMapFlat.find(el => el.to === pathname);

    return (
        <WebsiteNavigationContext.Provider value={{
            getMenuItemByPathname,
            sitemap: mySiteMap,
            sitemap__flat: mySiteMapFlat
        }}>
            {children}
        </WebsiteNavigationContext.Provider>
    )
};

export default useWebsiteNavigation;

/*

                {
                    "to": `${baseUrl}/documents/advice-reports`,
                    "title": "Advice Reports",
                    "text": "View your past and current advice reports including your yearly pre-view document.",
                    "icon": "fa-file-pdf",
                    "iconClass": "text-red-300",
                    "requiredRoles": ["advice_report_access"]
                },
{
            "title": "Communications",
            "menu": [
                {
                    "to": "/personal/fact-find",
                    "title": "Fact Find",
                    "text": "View and edit your personal information that appears on our Fact Find.",
                    "icon": "fa-user",
                    "iconClass": "text-green-300",
                    "requiredRoles": ["fact_find_access"]
                },
                {
                    "to": "/personal/notifications",
                    "title": "Notifications",
                    "text": "View notifications that need your attention.",
                    "icon": "fa-bell",
                    "iconClass": "text-red-300",
                    "requiredRoles": ["communications_enabled"]
                },
                {
                    "to": "/personal/appointments",
                    "title": "Appointments",
                    "text": "View past, upcoming or schedule your next appointment with your adviser.",
                    "icon": "fa-calendar-days",
                    "iconClass": "text-purple-300",
                    "requiredRoles": ["communications_enabled"]
                },
                {
                    "to": "/personal/messages",
                    "title": "Messages",
                    "text": "View your past or send a new message to your adviser and their support staff.",
                    "icon": "fa-comments",
                    "iconClass": "text-blue-300",
                    "requiredRoles": ["communications_enabled"]
                },
                {
                    "to": "/personal/video-meetings",
                    "title": "Video Meeting",
                    "text": "Start or Schedule your online review meeting with your adviser.",
                    "icon": "fa-video",
                    "iconClass": "text-orange-300",
                    "requiredRoles": ["communications_enabled"]
                }
            ]
        },
        {
            "title": "Planning Tools",
            "menu": [
                {
                    "to": "/tools/planning-timeline",
                    "title": "Planning Timeline",
                    "text": "View your past and current business sheets.",
                    "icon": "fa-calendar-days",
                    "iconClass": "text-green-300",
                    "requiredRoles": ["planning_tools_enabled"]
                },
                {
                    "to": "/tools/investment-products",
                    "title": "Investment Products",
                    "text": "View your past and current advice reports including your yearly pre-view document.",
                    "icon": "fa-table-list",
                    "iconClass": "text-red-300",
                    "requiredRoles": ["planning_tools_enabled"]
                },
                {
                    "to": "/tools/income-and-expenses",
                    "title": "Income and Expenses",
                    "text": "View your yearly tax packs.",
                    "icon": "fa-credit-card",
                    "iconClass": "text-purple-300",
                    "requiredRoles": ["planning_tools_enabled"]
                },
                {
                    "to": "/tools/regular-savings",
                    "title": "Regular Savings & Investments",
                    "text": "View your current contract notes.",
                    "icon": "fa-arrow-right",
                    "iconClass": "text-blue-300",
                    "requiredRoles": ["planning_tools_enabled"]
                },
                {
                    "to": "/tools/withdrawals",
                    "title": "Withdrawals",
                    "text": "View your quarterly custody statements.",
                    "icon": "fa-arrow-left",
                    "iconClass": "text-orange-300",
                    "requiredRoles": ["planning_tools_enabled"]
                },
                {
                    "to": "/tools/inflation-growth-rates",
                    "title": "Inflation & Growth Rates",
                    "text": "View your quarterly cost disclosures.",
                    "icon": "fa-chart-line",
                    "iconClass": "text-fuchsia-300",
                    "requiredRoles": ["planning_tools_enabled"]
                },
                {
                    "to": "/tools/cash-flow-results",
                    "title": "Cash Flow Results",
                    "text": "View your quarterly cost disclosures.",
                    "icon": "fa-square-check",
                    "iconClass": "text-cyan-300",
                    "requiredRoles": ["planning_tools_enabled"]
                }
            ]
        },
                        // {
                //     "to": "/markets",
                //     "title": "Markets",
                //     "text": "Read about current Market news.",
                //     "icon": "fa-earth-europe",
                //     "iconClass": "text-green-300"
                // },
                // {
                //     "to": "/articles",
                //     "title": "Opinions & Articles",
                //     "text": "Read our opinions and previous articles about the current market.",
                //     "icon": "fa-newspaper",
                //     "iconClass": "text-red-300"
                // }
*/