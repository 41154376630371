import { useGetOurViewQuery } from "../../../redux";

const useOurView = () => {
    const data = useGetOurViewQuery();
    
    return {
        ...data
    };
};

export default useOurView;