import react from "react";
import SummaryWrapper from "./SummaryWrapper";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import LoadingSkeleton from "./LoadingSkeleton";
import Money from "../../../components/Money";
import usePerformanceViewSelector from "../hooks/usePerformanceVolatilityStatistics";
import Percentage from "../../../components/Percentage";

const HistoricalVolatilityStatistics = () => {

const { data, error, isFetching, isLoading } = usePerformanceViewSelector();
   
if(isLoading || isFetching){
    return(
        <SummaryWrapper>
            <LoadingSkeleton />
        </SummaryWrapper>
    );
}

if(!error && data == null || data.length <= 0){
    return (
        <SummaryWrapper>
            <div className="flex flex-col w-full">
                <table className="w-full border-separate border-spacing-x-2 table-fixed text-zinc-600 text-xs lg:text-base">
                    <thead>
                        <tr className="text-blue-900">
                            <th className="md:w-[50%]">Fund</th>
                            <th className="text-right">Value</th>
                            <th className="text-right break-normal">Vol (3 Year Ann)</th>
                            <th className="text-right break-normal">Max DD (3 Year Ann)</th>
                        </tr>
                    </thead>
                    <tbody className="overflow-x-auto">
                        <tr>
                            <td>There are no investments to show.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </SummaryWrapper>
    );
}

if(error){
    return (
        <SummaryWrapper>
            <div className="flex flex-col w-full text-center text-zinc-600">
                <FetchErrorMessage />
            </div>
        </SummaryWrapper>
    );
}     

return (
    <>
     {data != null && data.length > 0 && data.map((el, i) => (
            <div key={i} className="mb-3">
                <SummaryWrapper type="accordion" title={el.description} value={el.totalFundValues}>
                    <table className="w-full table-fixed p-6 text-zinc-600">
                        <thead>
                            <tr className="text-blue-900">
                            <th className="md:w-[50%]">Fund</th>
                                <th className="text-right">Value</th>
                                <th className="text-right break-normal">Vol (3 Year Ann)</th>
                                <th className="text-right break-normal">Max DD (3 Year Ann)</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-x-auto">
                            {el.subGroupings.length > 0 && el.subGroupings.map((c) => (
                                <>
                                    {c.investments.length > 0 && c.investments.map((inv, index) => (
                                        <tr key={index}>
                                            <td className="truncate">{inv.investmentName}</td>
                                            <td className="text-right tabular-nums"><Money prefix={'£'} value={inv.value}  showNil={false} /></td>
                                            <td className="text-right tabular-nums"><Percentage value={inv.volatility3Year} decimalPlaces={2}/></td>
                                            <td className="text-right tabular-nums"><Percentage value={inv.maxDD3Year} decimalPlaces={2}/></td>
                                        </tr>
                                    ))}
                                </>
                            ))}
                        </tbody>
                    </table>
                </SummaryWrapper>
            </div>
        ))}
    </>
)
}

export default HistoricalVolatilityStatistics;