import React from "react";
import Accordion, { StaticAccordion } from "../../../components/Accordion";
import Money from "../../../components/Money";

const SummaryWrapper = ({type="static", title="", value=0, allocation=0, children}) => {

    if(type == "accordion"){
        return(
            <Accordion className="py-4 px-6" titleClassName="bg-gradient-to-r from-blue-900 via-indigo-700 to-indigo-600 text-white px-6" title={(
                <table className="w-full table-fixed">
                    <thead>
                        <tr>
                            <th className="truncate md:w-[70%]">{title}</th>
                            <th className="text-right tabular-nums pr-4"><Money prefix={'£'} value={value} showNil={false} /></th>
                        </tr>
                    </thead>
                </table>
            )}>
              {children}
            </Accordion>
        )
    }

    return (
        <>
            <StaticAccordion className="py-4 px-6" titleClassName="bg-gradient-to-r from-blue-900 via-indigo-700 to-indigo-600 text-white px-6" title={(
                <table className="w-full table-fixed">
                    <thead>
                        <tr>
                            <th className="truncate md:w-[70%]">{title}</th>
                            <th className="text-right tabular-nums"><Money prefix={'£'} value={value} showNil={false} /></th>
                        </tr>
                    </thead>
                </table>
            )}>
              {children}
            </StaticAccordion>
        </>
    );
};

export default SummaryWrapper;