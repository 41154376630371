import React from "react";
import Skeleton from "../../../components/Skeleton";

const LoadingSkeleton = () => {
    return (
        <div className="flex">
            <div className="my-3 w-full p-1">
                <div class="flex flex-row gap-4">
                    <Skeleton height="h-4" className="basis-6/12" />
                    <Skeleton height="h-4" className="basis-3/12" />
                    <Skeleton height="h-4" className="basis-3/12" />
                </div>
                <div class="flex flex-row gap-4 my-3">
                    <Skeleton height="h-4" className="basis-6/12" />
                    <Skeleton height="h-4" className="basis-3/12" />
                    <Skeleton height="h-4" className="basis-3/12" />
                </div>
                <div class="flex flex-row gap-4">
                    <Skeleton height="h-4" className="basis-6/12" />
                    <Skeleton height="h-4" className="basis-3/12" />
                    <Skeleton height="h-4" className="basis-3/12" />
                </div>
            </div>
        </div>
    );
};

export default LoadingSkeleton;
