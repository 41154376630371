import React from "react";
import Button from "../components/Button";
import { Helmet } from "react-helmet-async";
import { useSession } from "../components/SessionContext";
import { useNavigate, useSearchParams } from "react-router-dom";

const NotAuthorisedPage = ({ dashboardLink = "/" }) => {
    const auth = useSession();
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const { user: { emailAddress: email, name } } = auth;
    
    const signoutRedirect = () => { } // TODO: change this for logout hook

    const onNavigateToDashboard = () =>  navigate(dashboardLink, { replace: true });

    const onSignOut = () => signoutRedirect({ 
        post_logout_redirect_uri: !searchParams.get('redirect')
            ? window.location.origin
            : decodeURIComponent(searchParams.get('redirect'))
    });

    return (
        <React.Fragment>
            <Helmet>
                <title>Access Denied: WealthPro</title>
            </Helmet>
            <div className="flex flex-1 md:justify-center md:items-center">
                <div className="flex flex-col md:flex-row md:max-w-screen-lg md:items-center">
                    <div className="flex justify-center items-center md:p-3">
                        <span className="font-bold text-[12rem] text-slate-400">403</span>
                    </div>
                    <div className="px-3">
                        <h1 className="font-bold text-3xl text-blue-500">Whoops!</h1>
                        <p className="my-4 text-sm text-slate-600">Sorry, but {name} &lt;{email}&gt; is not authorised to access this content. Your account
                        is missing the required roles to view this information. If you believe you should be able to
                        see this content, please speak with your adviser who will be able to give you access.</p>
                        <div className="flex flex-col md:flex-row gap-2">
                            <Button onClick={onSignOut}>Sign out and connect with another login</Button>
                            <Button onClick={onNavigateToDashboard}>Back to Dashboard</Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        
    );
};

export default NotAuthorisedPage;