import React from "react";
import Button from "../Button";
import FormInput from "./FormInput";
import CompanyLogo from "../CompanyLogo";
import DisplayCard from "../DisplayCard";
import LoadingSpinner from "../../features/portfoliovaluation/components/LoadingSpinner";
import useResetPasswordRequest from "../hooks/useResetPasswordRequest";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";

const ResetPasswordRequestForm = () => {
    const [hasRequested, setHasRequested] = React.useState(false);
    const [emailRequested, setEmailRequested] = React.useState('');
    const { reset, isLoading } = useResetPasswordRequest();
    const { control, formState: { errors }, handleSubmit, reset: resetForm, setError } = useForm({ defaultValues: { emailAddress: '' } });

    const _submit = ({ emailAddress }) => {
        reset(emailAddress)
            .then(
                r => { 
                    resetForm({ emailAddress: '' });
                    setHasRequested(_ => true);
                },
                e => {
                    const { message, statusCode } = e.data;
                    resetForm({ emailAddress: '' });
                    setError("root.result", { type: statusCode, message });
                }
            )
            .finally(_ => setEmailRequested(_ => emailAddress));
    };

    return (
        <DisplayCard className="bg-white text-black p-4 mx-4 lg:mx-0 max-w-xl">
            <div className="flex flex-col justify-center items-center p-3">
                <CompanyLogo />
            </div>
            <div className="flex flex-col justify-center items-center mb-4">
                <h1 className="text-xl text-gray-500 font-bold">Forgot Password</h1>
            </div>
            {!hasRequested && (
                <React.Fragment>
                    <p className="flex-1 text-sm text-gray-500">
                        Please enter your email address that is associated with your account below. If an account exists with that email then we will email you a reset code.
                    </p>
                    <form className="flex flex-col gap-3 w-full my-4 border p-5" onSubmit={handleSubmit(_submit)}>
                        {errors?.root?.result && (
                            <div className="">
                                <p className="m-0 text-sm text-red-500">{errors?.root?.result?.message ?? "There was a problem with your request."}</p>
                            </div>
                        )}
                        <div className="w-full">
                            <Controller 
                                name="emailAddress"
                                control={control}
                                rules={({ required: 'Verification code required', minLength: 3, maxLength: 2048 })}
                                render={({ field: { ref, ...rest } }) => (
                                    <FormInput 
                                        type="email" 
                                        pClassName="mb-0"
                                        label="Email Address" 
                                        maxLength={2048} 
                                        disabled={isLoading}
                                        autoComplete="email" 
                                        {...rest} 
                                    />
                                )}
                            />
                        </div>
                        <Button className="w-full text-center" type="submit" disabled={isLoading}>
                            {!isLoading ? (<>Reset password</>) : (<LoadingSpinner width={3} height={3} />)}
                        </Button>
                    </form>
                </React.Fragment>
            )}
            {hasRequested && (
                <p className="flex-1 text-sm border p-5 text-gray-500">
                    Thank you, an email has been sent to: <span className="font-bold">'{emailRequested}'</span>. You can safely close this tab or click <Link className="font-medium text-blue-600 dark:text-blue-500 hover:underline" to="/signin" reloadDocument>here to return to the sign in page</Link>.
                </p>
            )}
        </DisplayCard>
    );
};

export default ResetPasswordRequestForm;