import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FetchErrorMessage = ({
    icon = "fa-triangle-exclamation",
    iconClassName = "text-5xl my-3",
    textClassName = "text-sm"
}) => {
    return (
        <React.Fragment>
            <FontAwesomeIcon className={iconClassName} icon={icon} />
            <p className={textClassName}>We're Sorry! There was a problem fetching your data. Please try refreshing the page and if the issue persists, contact your adviser or assistance team on XXXX XXXXXX</p>
        </React.Fragment>
    );
};

export default FetchErrorMessage;