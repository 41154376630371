// import axios from 'axios';
// import { oidcClient } from "./redux/token";

// let tokenData = oidcClient.getOidcToken();

// export default axios.create({
//   baseURL: "/",
//   headers : {'Authorization': `${tokenData?.token_type} ${tokenData?.access_token}`}
// });

import axios from "axios";

export default axios.create({
	baseURL: "/",
	withCredentials: true,
	validateStatus: (status) => {
		return status < 500; // TODO: filter out just the 401 & 403
	}
});