import React, {useState} from "react";
import Moment from "react-moment";
import Button from "../../../components/Button";
import NewDisplayBadge from "../../../components/NewDisplayBadge";
import DocumentIcon from "./DocumentIcon";
import useDocumentList from "../hooks/useDocumentList";
import axiosConfig from "../../../axiosConfig";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";
import LoadingSpinner from "../../portfoliovaluation/components/LoadingSpinner";
import { useSession } from "../../../components/SessionContext";

export const DocumentsListLoader = () => {
    return (
        <div className="p-6">
            <div className="animate-pulse bg-slate-200 w-full h-7 text-left border border-slate-200 rounded mb-2" />
            <div className="animate-pulse bg-slate-200 w-full h-7 text-left border border-slate-200 rounded mb-2" />
            <div className="animate-pulse bg-slate-200 w-full h-7 text-left border border-slate-200 rounded mb-2" />
        </div>
    );
};

export const SignatureRequiredDisplayBadge = (props) => {
    return (
        <div {...props}>
            <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
                Signature Required
            </span>
        </div>
    );
};

// {isSignatureRequired && (!signatures || signatures.length === 0 || signatures.filter(el => el.signedOnDateUtc === null).length > 0) && <SignatureRequiredDisplayBadge />}

const DocumentsListRecord = ({ data }) => {
    const { accessToken } = useSession();
    const { clientFirstOpenedOnDateUtc, isSignatureRequired, signatures } = data;

    const [isDownloading, setIsDownloading] = useState(false);


    const getDocument = () => {
        if (!accessToken) {
            toast.error("Could not download file. You do not have the required permissions to access this file.");
            return;
        }

        setIsDownloading(true);
        axiosConfig.get(`api/documents/${data.documentId}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            responseType: "blob",
        })
        .then((res) => {
            if (res.status !== 200) {
                toast.error('There was an error whilst trying to get your file.');
                return;
            }
            fileDownload(res.data, data.name);
        })
        .catch(() => {
            toast.error("Could not download file. You do not have the required permissions to access this file.");
        })
        .finally(() => {
            setIsDownloading(false);
        });
    };

    const hasDocumentBeenSigned = () => {
        if (isSignatureRequired === false) {
            return true;
        }

        if (!signatures || signatures.length === 0) {
            return false;
        }
    };

    return (
        <div className="flex flex-col md:flex-row md:items-center border-b border-slate-300 px-4 md:px-0 py-4">
            <div className="mx-6 hidden md:block">
                <DocumentIcon fileType={data.fileType} />
            </div>
            <p className="flex-1 leading-none">
                <DocumentIcon className="me-3 md:hidden" fileType={data.fileType} />
                <span className="text-md">{data.name}</span>
                <br />
                <span className="text-xs text-slate-500">
                    Uploaded{" "}
                    <Moment date={data.publishedDateUtc} format="MMMM Do" />
                </span>
            </p>
            {!clientFirstOpenedOnDateUtc && <NewDisplayBadge />}
            {hasDocumentBeenSigned() === false && (
                <SignatureRequiredDisplayBadge />
            )}
            <div className="me-6">
                <Button onClick={getDocument}>{!isDownloading ? (<>Download</>) : (<LoadingSpinner width={3} height={3} />)}</Button>
            </div>
        </div>
    );
};

const DocumentsList = ({ slug, year }) => {
    const { data, error, isError, isFetching, isLoading } = useDocumentList(
        slug,
        year
    );
    const { documents } = data || { documents: [] };

    if (isFetching || isLoading) {
        return <DocumentsListLoader />;
    }

    return documents.map((el, i) => (
        <DocumentsListRecord key={`${year}-${i}`} data={el} />
    ));
};

export default DocumentsList;
