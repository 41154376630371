import { useGetPortfolioGainQuery } from "../../../redux";

const usePortfolioGain = () => {
    
    const data = useGetPortfolioGainQuery();

    return {
        ...data
    };
};

export default usePortfolioGain;