/**
 * Auth is a slice that will provide endpoints for authenticating and refresh session/jwt/access tokens
 * The main API for communicating with WP3 services can then hook into the slice and get the access token from the internal state
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accessToken: null,
    user: null,
    isLoading: true,
    isUninitialized: true
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: { 
        clear: (state, action) => {
            state.accessToken = null;
            state.user = null;
            state.isLoading = false;
        },
        setLoadingAuth: (state, action) => {
            const { isLoading } = action.payload;
            state.isLoading = isLoading;
        },
        setAccessToken: (state, action) => {
            const { accessToken, user } = action.payload;

            state.accessToken = accessToken;
            state.user = user;
            state.isLoading = false;

            if (state.isUninitialized === true) {
                state.isUninitialized = false;
            }
        }
    },
    extraReducers: (builder) => { }
});

export const authReducer = slice.reducer;
export const { clear, setLoadingAuth, setAccessToken } = slice.actions;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectIsLoading = (state) => state.auth.isLoading;
export const selectIsUninitialized = (state) => state.auth.isUninitialized;
export const selectUser = (state) => state.auth.user;