import React from "react";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import DisplayCard, { DisplayCardBody } from "../../../components/DisplayCard";
import Skeleton from "react-loading-skeleton";
import Chart from "chart.js/auto";
import {Pie} from "react-chartjs-2";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

const ChartDisplayCard = ({ inputData = null, error = null, isLoading = false, title = "", titleColor = "text-red-500" }) => {

    const {labels, data, backgroundColor} = inputData || {labels: null, data: null, backgroundColor: null};

    const chartData = {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgroundColor,
          hoverOffset: 25
        }],
        options: {
            responsive: true
        }
    };

    if(isLoading){
        <DisplayCard className={`w-full shadow-md bg-white border border-slate-300 rounded ${isLoading === true ? 'animate-pulse h-48' : ''}`}>
        {isLoading === false && (
            <DisplayCardBody className="text-center">
                <h2 className={`card-title justify-center text-md uppercase mb-3 ${titleColor}`}>{title}</h2>
               <Skeleton />
            </DisplayCardBody>
        )}
    </DisplayCard>
    }
    
    
    return (
        <DisplayCard className={`w-full shadow-md bg-white border border-slate-300 rounded ${isLoading === true ? 'animate-pulse h-48' : ''}`}>
            {isLoading === false && (
                <DisplayCardBody className="text-center">
                    <h2 className={`card-title justify-center text-md uppercase mb-3 ${titleColor}`}>{title}</h2>
                    {data && !error && (
                        <Pie
                            className="max-h-72 xl:max-h-96"
                            data={chartData}
                        />
                    )}
                    {(!data || error) && (
                        <div className="text-center">
                            <FetchErrorMessage />
                        </div>
                    )}
                </DisplayCardBody>
            )}
        </DisplayCard>
    );
};

export default ChartDisplayCard;