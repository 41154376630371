import React, { useMemo } from "react";

const Money = ({
    decimalPlaces = 0,
    prefix = "£",
    showNil = true,
    value = 0
}) => {
    const calculateDisplayValue = () => {

        if (typeof (value) !== "number" || (showNil === true && value === 0)) {
            if(showNil){
                return <span>NIL</span>
            }

            return <span>-</span>
        }

        return prefix + value.toFixed(decimalPlaces).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    return <span>{calculateDisplayValue()}</span>
};

export default Money;