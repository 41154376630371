import react from "react";
import SummaryWrapper from "./SummaryWrapper";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import LoadingSkeleton from "./LoadingSkeleton";
import Money from "../../../components/Money";
import usePerformancePortfolioSummary from "../hooks/usePerformancePortfolioSummary";
import Moment from "react-moment";
import Percentage from "../../../components/Percentage";

const PortfolioPerformanceSummary = () => {

    const { data, error, isFetching, isLoading } = usePerformancePortfolioSummary();

    if(isLoading || isFetching){
        return(
            <SummaryWrapper>
                <LoadingSkeleton />
            </SummaryWrapper>
        );
    }

    if(error){
        return (
            <SummaryWrapper>
                <div className="flex flex-col w-full text-center text-zinc-600">
                    <FetchErrorMessage />
                </div>
            </SummaryWrapper>
        );
    }     

    return (<SummaryWrapper type="static" title={"All Funds and Investments"} value={data.totalFundValues}>
                <table className="w-full table-fixed p-6 text-zinc-600">
                    <thead>
                        <tr className="text-blue-900">
                            <th className="md:w-[50%]">Fund</th>
                            <th className="text-right hidden lg:table-cell">Invested</th>
                            <th className="text-right hidden md:table-cell">Amount</th>
                            <th className="text-right">Value</th>
                            <th className="text-right">Gain %</th>
                        </tr>
                    </thead>
                    <tbody className="overflow-x-auto">
                        {data.investments.length > 0 && data.investments.map((c, index) => (
                            <tr key={index}>
                                <td className="truncate">{c.investmentName}</td>
                                <td className="text-right tabular-nums hidden lg:table-cell"><Moment date={c.initialInvestmentDate} format="DD/MM/YYYY" /></td>
                                <td className="text-right tabular-nums hidden md:table-cell"><Money prefix={'£'} value={c.invested}  showNil={false} /></td>
                                <td className="text-right tabular-nums"><Money prefix={'£'} value={c.value}  showNil={false} /></td>
                                <td className="text-right tabular-nums"><Percentage value={c.gain} decimalPlaces={2} /></td>
                            </tr>
                        ))}    
                        {data == null || data.investments.length <= 0 && 
                            <tr>
                            <td>There are no investments to show.</td>
                            </tr>
                        }        
                    </tbody>
                </table>
            </SummaryWrapper>
    )
}

export default PortfolioPerformanceSummary;