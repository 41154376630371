import React from "react";
import useOurView from "./hooks/useOurView";
import FetchErrorMessage from "../../components/FetchErrorMessage";
import Moment from "react-moment";

const OurView = () => {

    const { data, error, isError, isFetching, isLoading } = useOurView();

    const { ourViewText, createdDate, publishedBy } = data || {
        ourViewText: null,
        createdDate: null,
        publishedBy: null,
    };

    return (
        <div
            className={`items-center border bg-white  rounded border-slate-300 my-4 p-4 ${
                isLoading === true ? "animate-pulse h-24" : ""
            }`}
        >
            {!error && ourViewText && (
                <div className="flow-root">
                    <span
                        className="prose max-w-none"
                        dangerouslySetInnerHTML={{
                            __html: ourViewText,
                        }}
                        ></span>
                </div>
            )}
            {!error && ourViewText && createdDate && (
                <div className="flow-root mt-4">
                    <span className="text-xs italic float-right">
                        published on{" "}
                        <Moment date={createdDate} format="Do MMMM YYYY" />
                    </span>
                </div>
            )}
            {(!ourViewText || ourViewText == null || ourViewText == "") && !error && !isLoading && (
                <div>
                    <p>
                        We have not published our current opinion at this time.
                    </p>
                </div>
            )}
            {error && (
                <div className="text-center">
                    <FetchErrorMessage />
                </div>
            )}
        </div>
    );
};

export default OurView;
