
import { useGetInvestmentsByOwnerSummaryQuery } from "../../../redux";

const usePortfolioInvestmentsByOwner = () => {
    
    const data = useGetInvestmentsByOwnerSummaryQuery();

    return {
       ...data
    };
};

export default usePortfolioInvestmentsByOwner;