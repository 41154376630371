import wealthProApi from "../api";

const valuationEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        getPortfolioWealth: builder.query({
            query: () => 'api/valuations/total-wealth'
        }),
        getPortfolioGain: builder.query({
            query: () => 'api/valuations/total-gain'
        }),
         getValuationProfile: builder.query({
            query: () => 'api/valuations/profile'
        }),
        getValuationSummary: builder.query({
            query: () => 'api/valuations/summary'
        }),
        getInvestmentsSummary: builder.query({
            query: () => 'api/valuations/investments'
        }),
        getInvestmentsByShareClassSummary: builder.query({
            query: () => 'api/valuations/shareclass'
        }),
        getInvestmentsByVolatilitySummary: builder.query({
            query: () => 'api/valuations/volatility'
        }),
        getInvestmentsByOwnerSummary: builder.query({
            query: () => 'api/valuations/owner'
        }),
        getInvestmentsByProductSummary: builder.query({
            query: () => 'api/valuations/product'
        }),
        getInvestmentsByUnitsAndSharesSummary: builder.query({
            query: () => 'api/valuations/unit-shares'
        })
    })
});

export const {
    useGetPortfolioWealthQuery,
    useGetPortfolioGainQuery,
    useGetValuationProfileQuery,
    useGetValuationSummaryQuery,
    useGetInvestmentsSummaryQuery,
    useGetInvestmentsByShareClassSummaryQuery,
    useGetInvestmentsByVolatilitySummaryQuery,
    useGetInvestmentsByOwnerSummaryQuery,
    useGetInvestmentsByProductSummaryQuery,
    useGetInvestmentsByUnitsAndSharesSummaryQuery,
} = valuationEndpoints;