import wealthProApi from "../api";

const performanceEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        getHistoricalReturn: builder.query({
            query: () => 'api/portfolioperformance/historicalreturn'
        }),
        getVolatilityReturn: builder.query({
            query: () => 'api/portfolioperformance/volatilityreturn'
        }),
        getVolatilityStatistics: builder.query({
            query: () => 'api/portfolioperformance/volatilitystatistics'
        }),
        getPortfolioSummary: builder.query({
            query: () => 'api/portfolioperformance/portfoliosummary'
        }),
        getProductSummary: builder.query({
            query: () => 'api/portfolioperformance/productsummary'
        }),
    })
});

export const {
    useGetHistoricalReturnQuery,
    useGetVolatilityReturnQuery,
    useGetVolatilityStatisticsQuery,
    useGetPortfolioSummaryQuery,
    useGetProductSummaryQuery,
} = performanceEndpoints;