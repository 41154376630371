import { useGetInvestmentsByUnitsAndSharesSummaryQuery } from "../../../redux";

const usePortfolioInvestmentsByUnitsAndShares = () => {
    
    const data = useGetInvestmentsByUnitsAndSharesSummaryQuery();

    return {
       ...data
    };
};

export default usePortfolioInvestmentsByUnitsAndShares;