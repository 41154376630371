import React, { useMemo, useState } from "react";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import PortfolioValuation from "../features/portfoliovaluation";

const PortfolioValuationPage = () => {
   return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader>Valuation</WebsitePageHeader>
                <PortfolioValuation />
            </div>
        </React.Fragment>
    )
};

export default PortfolioValuationPage;