import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const wealthProApi = createApi({
   reducerPath: 'wealthProApi',
   baseQuery: fetchBaseQuery({
      baseUrl: "/",
      prepareHeaders: async (headers, { getState }) => {
         const { auth } = getState();
         const { accessToken } = auth;

         if (accessToken && typeof(accessToken) === 'string') {
            headers.set('Authorization', `Bearer ${accessToken}`);
         }

         return headers;
      }
   }),
   refetchOnMountOrArgChange: true,
   endpoints: () => ({})
});

export default wealthProApi;