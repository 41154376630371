import React from "react";
import WealthProLogo from "./WealthProLogo";
import { NavLink } from "react-router-dom";

const WebsiteHero = ({ children }) => (
    <React.Fragment>
        <div className="min-h-screen flex flex-col bg-gradient-to-b from-indigo-600 via-indigo-700 to-blue-900 text-white">
            <header className="p-5 flex flex-nowrap items-center sticky w-full">
                <NavLink to="/">
                    <WealthProLogo className="w-24" />
                </NavLink>
            </header>
            <section className="flex-1 flex flex-col items-center xl:justify-center xl:-mt-6">
                {children}
            </section>
        </div>
    </React.Fragment>
);

export default WebsiteHero;