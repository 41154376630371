import React from "react";
import FundCommentaryCard from "./components/FundCommentaryCard";
import useCoreFundList from "./hooks/useCoreFundList";
import FundCommentaryLoadingPlaceholder from "./components/FundCommentaryLoadingPlaceholder";
import FetchErrorMessage from "../../components/FetchErrorMessage";

const FundCommentary = () => {

    const { data, error, isFetching, isLoading } = useCoreFundList();

    if(isLoading){
        return <FundCommentaryLoadingPlaceholder />
    }

    if(!error && data.length == 0){
        return <div className={`flex flex-col w-full text-center border bg-white rounded border-slate-300 my-4 p-4 shadow-inner`}>
            <p>There are no funds available.</p>
        </div>
    }

    if(error){
        return <div className={`flex flex-col w-full text-center border bg-white rounded border-slate-300 my-4 p-4 shadow-inner`}>
            <FetchErrorMessage />
        </div>
    }
    
    if(data != null && data.length > 0){
        return data.map((el, i) => (<FundCommentaryCard key={i} data={el} />));
    }
};

export default FundCommentary;