import { useGetFundFactSheetListQuery } from "../../../redux";

const useCoreFundList = () => {
    
    const data = useGetFundFactSheetListQuery();

    return {
        ...data
    };
};

export default useCoreFundList;