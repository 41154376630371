import React from "react";

const LoadingPage = () => {
    return (
        <main className="flex flex-col flex-1 justify-center items-center">
            <span className="text-5xl font-bold p-5 bg-gradient-to-r from-slate-500 via-slate-400 to-slate-300 text-transparent bg-clip-text bg-300% animate-gradient">
                WealthPro...
            </span>
        </main>
    );
};

export default LoadingPage;