import { useDispatch } from "react-redux";
import { useVerifyCodeMutation } from "../../redux";
import { setAccessToken } from "../../redux/auth";

const useLoginVerification = () => {
    const dispatch = useDispatch();
	const [_verify, { isLoading, ...verifyData }] = useVerifyCodeMutation();

	const verify = (sessionId, verificationCode) => {
		return new Promise((resolve, reject) => {
			return _verify({ sessionId, verificationCode })
				.unwrap()
				.then(
					r => { 
						const { accessToken, user } = r;
						dispatch(setAccessToken({ accessToken, user }));
						window.location.href = '/dashboard';
						return resolve(r);
					},
					e => { 
						return reject(e);
					}
				)
		});	
	};

	return { verify, isLoading, ...verifyData };
};

export default useLoginVerification;