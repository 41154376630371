import React from "react";

const NewDisplayBadge = (props) => {
    return (
        <div {...props}>
            <span className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">New</span>
        </div>
    );
};

export default NewDisplayBadge;