import { useGetPortfolioSummaryQuery } from "../../../redux";

const usePerformancePortfolioSummary = () => {
    const data = useGetPortfolioSummaryQuery();
  
    return {
        ...data
    };
};

export default usePerformancePortfolioSummary;