import React from "react";
import SummaryWrapper from "./SummaryWrapper";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import LoadingSkeleton from "./LoadingSkeleton";
import Money from "../../../components/Money";
import usePerformanceVolatilityReturn from "../hooks/usePerformanceVolatilityReturn";
import Percentage from "../../../components/Percentage";

const HistoricalTotalReturnByVolatilitySummary = () => {

  
    const { data, error, isFetching, isLoading } = usePerformanceVolatilityReturn();

    if(isLoading || isFetching){
        return(
            <SummaryWrapper>
                <LoadingSkeleton />
            </SummaryWrapper>
        );
    }

    if(!error && data == null || data.length <= 0){
        return (
            <SummaryWrapper>
                <div className="flex flex-col w-full">
                    <table className="w-full border-separate border-spacing-x-2 table-fixed text-zinc-600 text-xs lg:text-base">
                        <thead>
                        <tr className="text-blue-900">
                                <th className="md:w-[50%]">Fund</th>
                                    <th className="text-right hidden lg:table-cell">Value</th>
                                    <th className="text-right">1M</th>
                                    <th className="text-right hidden xl:table-cell">3M</th>
                                    <th className="text-right">6M</th>
                                    <th className="text-right">1Y</th>
                                    <th className="text-right">3Y(PA)</th>
                                    <th className="text-right hidden xl:table-cell">5Y(PA)</th>
                                </tr>
                        </thead>
                        <tbody className="overflow-x-auto">
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </SummaryWrapper>
        );
    }

    if(error){
        return (
            <SummaryWrapper>
                <div className="flex flex-col w-full text-center text-zinc-600 text-xs lg:text-base">
                    <FetchErrorMessage />
                </div>
            </SummaryWrapper>
        );
    }     

    return (
        <>
            {data != null && data.length > 0 && data.map((el, i) => (
                <div key={i} className="mb-3">
                    <SummaryWrapper type="accordion" title={el.description} value={el.totalFundValues}>
                        <table className="w-full table-fixed p-6 text-zinc-600 text-xs lg:text-base">
                            <thead>
                                <tr className="text-blue-900">
                                <th className="md:w-[50%]">Fund</th>
                                    <th className="text-right hidden lg:table-cell">Value</th>
                                    <th className="text-right">1M</th>
                                    <th className="text-right hidden xl:table-cell">3M</th>
                                    <th className="text-right">6M</th>
                                    <th className="text-right">1Y</th>
                                    <th className="text-right">3Y(PA)</th>
                                    <th className="text-right hidden xl:table-cell">5Y(PA)</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-x-auto">
                                {el.subGroupings.length > 0 && el.subGroupings.map((c) => (
                                    <>
                                        <tr className="font-bold h-[5px]">
                                            <th colspan="3" className="md:w-[70%] truncate"></th>
                                        </tr>

                                        {c.investments.length > 0 && c.investments.map((inv, index) => (
                                            <tr key={index}>
                                                <td className="truncate">{inv.investmentName}</td>
                                                <td className="text-right tabular-nums hidden lg:table-cell"><Money prefix={'£'} value={inv.value} /></td>
                                                <td className="text-right tabular-nums"><Percentage value={inv.totalReturn1m} decimalPlaces={2}/></td>
                                                <td className="text-right tabular-nums hidden xl:table-cell"><Percentage value={inv.totalReturn3m} decimalPlaces={2} showNil={false} /></td>
                                                <td className="text-right tabular-nums"><Percentage value={inv.totalReturn6m} decimalPlaces={2} showNil={false} /></td>
                                                <td className="text-right tabular-nums"><Percentage value={inv.totalReturn12m} decimalPlaces={2} showNil={false} /></td>
                                                <td className="text-right tabular-nums"><Percentage value={inv.averageTotalReturn3Year} decimalPlaces={2} showNil={false} /></td>
                                                <td className="text-right tabular-nums hidden xl:table-cell"><Percentage value={inv.averageTotalReturn5Year} decimalPlaces={2} showNil={false} /></td>
                                            </tr>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </SummaryWrapper>
                </div>
            ))}
        </>
    )
}

export default HistoricalTotalReturnByVolatilitySummary;