import React from "react";
import useLogin from "../hooks/useLogin";
import Button from "../Button";
import FormInput from "./FormInput";
import LoadingSpinner from "../../features/portfoliovaluation/components/LoadingSpinner";
import { useSession } from "../SessionContext";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

const LoginForm = () => {
    const login = useLogin();
    const { isLoading: isSessionLoading } = useSession();
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues: { username: '', password: '' } });
    const [isLoading, setIsLoading] = React.useState(isSessionLoading);

    const _submit = ({ username, password }) => {
        login(username, password)
            .then(
                r => { },
                e => {
                    const { message, statusCode } = e.data;
                    reset({ username, password: '' });
                    setError("root.authResult", { type: statusCode, message });
                }
            )
    };

    React.useEffect(() => {
        if (!isSessionLoading && isLoading === true) {
            setIsLoading(_ => false);
        }
    }, [ isSessionLoading ]);
    
    return (
        <React.Fragment>
            <div className="flex flex-col justify-center items-center">
                <h1 className="text-lg text-gray-500 font-bold">Welcome back, please login to Wealthpro.</h1>
            </div>
            {errors?.root?.authResult && (
                <div className="mt-4">
                    <p className="m-0 text-sm text-red-500">{errors?.root?.authResult?.message ?? "There was a problem with your request."}</p>
                </div>
            )}
            <form className="flex flex-col gap-3 w-full my-4" onSubmit={handleSubmit(_submit)}>
                <Controller 
                    name="username"
                    control={control}
                    rules={({ required: 'Username is required' })}
                    render={({ field: { ref, ...rest } }) => (
                        <FormInput 
                            pClassName="mb-0" 
                            label="Username or email address" 
                            placeholder="username"
                            maxLength={255} 
                            disabled={isLoading}
                            autoComplete="username"
                            {...rest} 
                        />
                    )}
                />
                <Controller 
                    name="password"
                    control={control}
                    rules={({ required: 'Password is required' })}
                    render={({ field: { ref, ...rest } }) => (
                        <FormInput 
                            pClassName="mb-2" 
                            label="Password" 
                            placeholder="password" 
                            type="password"
                            maxLength={64}
                            disabled={isLoading}
                            autoComplete="current-password"
                            {...rest} 
                        />
                    )}
                />
                <Button className="w-full text-center" type="submit" disabled={isLoading}>
                    {!isLoading ? (<>Sign In</>) : (<LoadingSpinner width={3} height={3} />)}
                </Button>
            </form>
            <div className="flex justify-between items-center text-xs text-gray-400 px-1">
                <Link to={"/welcome"} reloadDocument>Register with Welcome Code</Link>
                <Link to={"/forgot-password"} reloadDocument>Forgot Password</Link>  
            </div>
        </React.Fragment>
    );
};

export default LoginForm;