import wealthProApi from "../api";

const sessionEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ['session']
}).injectEndpoints({
    endpoints: (builder) => ({
        signIn: builder.mutation({
            query: ({ ...data }) => {
                return {
                    url: 'session',
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['session']
        }),
        signOut: builder.mutation({
            query: () => {
                return {
                    url: 'session',
                    method: 'DELETE'
                }
            },
            invalidatesTags: ['session']
        }),
        getSession: builder.query({
            query: () => 'session',
            providesTags: ['session']
        })
    })
});

export const {
    useGetSessionQuery,
    useSignInMutation,
    useSignOutMutation
} = sessionEndpoints;