import { useGetInvestmentsSummaryQuery } from "../../../redux";

const usePortfolioInvestments = () => {
    const data = useGetInvestmentsSummaryQuery();

    return {
        ...data
    };
};

export default usePortfolioInvestments;