import React from "react";
import CookieConsent from "react-cookie-consent";

const WebsiteCookieConsent = () => {
    return (
        <CookieConsent 
            location="bottom"
            cookieName="wp_cookie_consent"
            disableButtonStyles={true}
            buttonText="I Understand"
            buttonClasses="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
            buttonWrapperClasses="mx-3"
            overlay
        >
            WealthPro Client Dashboard uses cookies to enhance your experience. It simply doesn't work without.
            We do not use cookies to serve ads, content or analyze your traffic. By clicking "I Understand", 
            you consent to our use of cookies.
        </CookieConsent>
    )
};

export default WebsiteCookieConsent;