import React from "react";
import WebsiteHero from "../components/WebsiteHero";
import useVerifyImpersonationHandshake from "../components/hooks/useVerifyImpersonationHandshake";
import { useSearchParams } from "react-router-dom";

const HandshakePage = () => {
    const [searchParams] = useSearchParams();
    const { verify, isLoading } = useVerifyImpersonationHandshake();

    React.useEffect(() => {
        if (!searchParams.has('sessionId') || !searchParams.has('code')) {
            window.location.href = '/signin';
        }
        verify(searchParams.get('sessionId'), searchParams.get('code'));
    }, [searchParams]);

    return (
        <WebsiteHero>
            {isLoading && (
                <div>
                    <span className="text-5xl font-bold p-5 bg-gradient-to-r from-slate-500 via-slate-400 to-slate-300 text-transparent bg-clip-text bg-300% animate-gradient">
                        Creating Handshake...
                    </span>
                </div>
            )}
        </WebsiteHero>
    );
};

export default HandshakePage;