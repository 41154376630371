import { useGetVolatilityReturnQuery } from "../../../redux";

const usePerformanceVolatilityReturn = () => {
    
    const data = useGetVolatilityReturnQuery();
    return {
        ...data
    };
};

export default usePerformanceVolatilityReturn;