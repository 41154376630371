import React from "react";
import Accordion, { StaticAccordion } from "../../../components/Accordion";
import usePortfolioInvestmentsByProduct from "../hooks/usePortfolioInvestmentsByProduct";
import Money from "../../../components/Money";
import LoadingSkeleton from "./LoadingSkeleton";
import TotalWealthDisplay from "./TotalWealthDisplay";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import PortfolioValuationInvestmentSummaryWrapper from "./PortfolioValuationInvestmentsSummaryWrapper";

const PortfolioValuationInvestmentSummaryByProduct = () => {

    const { data, error, isFetching, isLoading } = usePortfolioInvestmentsByProduct();
    
    if(isLoading || isFetching){
        return(
            <PortfolioValuationInvestmentSummaryWrapper>
                <LoadingSkeleton />
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(!error && data == null || data.length <= 0){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full">
                    <table className="w-full table-fixed p-6 text-zinc-600 text-xs lg:text-base">
                        <thead>
                            <tr className="text-blue-900">
                                <th className="md:w-[70%]"></th>
                                <th className="text-right">Value</th>
                                <th className="text-right">Allocation</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-x-auto">
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(error){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full text-center">
                    <FetchErrorMessage />
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }     

    return (
        <>            
            {data != null && data.length > 0 && data.map((el, i) => (
                <div key={i} className="mb-3">
                    <PortfolioValuationInvestmentSummaryWrapper type="accordion" title={el.description} value={el.totalFundValues} allocation={el.totalAllocation}>
                        <table className="w-full table-fixed p-6 text-zinc-600 text-xs lg:text-base">
                            <thead>
                                <tr className="text-blue-900">
                                <th className="md:w-[70%]"> </th>
                                    <th className="text-right">Value</th>
                                    <th className="text-right">Allocation</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-x-auto">
                                {el.investments.length > 0 && el.investments.map((inv, index) => (
                                    <tr key={index}>
                                        <td className="truncate">{inv.investmentName}</td>
                                        <td className="text-right tabular-nums"> <Money prefix={'£'} value={inv.value} showNil={false} /></td>
                                        <td className="text-right tabular-nums">{inv.allocation}%</td>
                                    </tr>
                                ))}      
                            </tbody>
                        </table>
                    </PortfolioValuationInvestmentSummaryWrapper>
                </div>
            ))}
        </>
    )
}

export default PortfolioValuationInvestmentSummaryByProduct;