import React from "react";
import Button from "../Button";
import FormInput from "./FormInput";
import CompanyLogo from "../CompanyLogo";
import DisplayCard from "../DisplayCard";
import LoginWelcomeDisplay from "../LoginWelcomeDisplay";
import LoadingSpinner from "../../features/portfoliovaluation/components/LoadingSpinner";
import useLoginVerification from "../hooks/useLoginVerification";
import { useSession } from "../SessionContext";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

const LoginVerificationForm = ({ sessionId, mfaType }) => {
    const { verify } = useLoginVerification();
    const { isAuthenticated, isLoading: isSessionLoading, user } = useSession();
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues: { verificationCode: '' } });
    const [isLoading, setIsLoading] = React.useState(isSessionLoading);

    const _submit = ({ verificationCode }) => {
        setIsLoading(_ => true);
        verify(sessionId, verificationCode)
            .then(
                r => { },
                e => {
                    const { message, statusCode } = e.data;
                    reset({ verificationCode: '' });
                    setError("root.authResult", { type: statusCode, message });
                }
            )
            .finally(() => {
                setIsLoading(_ => false);
            });
    };

    React.useEffect(() => {
        if (!isSessionLoading && isLoading === true) {
            setIsLoading(_ => false);
        }
    }, [ isSessionLoading ]);

    if (!sessionId || !mfaType) {
        return null;
    }

    return (
        <DisplayCard className="bg-white text-black p-4 mx-4 lg:mx-0 max-w-xl">
            <div className="flex flex-col justify-center items-center p-3">
                <CompanyLogo />
            </div>
            {isAuthenticated && !isLoading && (
                <LoginWelcomeDisplay user={user} />
            )}
            {(!isAuthenticated || isLoading) && (
                <React.Fragment>
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-xl text-gray-500 font-bold">Two-factor authentication required</h1>
                    </div>
                    <form className="flex flex-col gap-3 w-full my-4 border p-5" onSubmit={handleSubmit(_submit)}>
                        {errors?.root?.authResult && (
                            <div className="">
                                <p className="m-0 text-sm text-red-500">{errors?.root?.authResult?.message ?? "There was a problem with your request."}</p>
                            </div>
                        )}
                        <div className="w-full">
                            <Controller 
                                name="verificationCode"
                                control={control}
                                rules={({ required: 'Verification code required', minLength: 6, maxLength: 6 })}
                                render={({ field: { ref, ...rest } }) => (
                                    <FormInput 
                                        type="tel" 
                                        pClassName="mb-0"
                                        label="Verification Code" 
                                        maxLength={6} 
                                        disabled={isLoading}
                                        autoComplete="one-time-code" 
                                        {...rest} 
                                    />
                                )}
                            />
                        </div>
                        <Button className="w-full text-center" type="submit" disabled={isLoading}>
                            {!isLoading ? (<>Verify</>) : (<LoadingSpinner width={3} height={3} />)}
                        </Button>
                    </form>
                    <div className="flex gap-x-2">
                        <div>
                            <FontAwesomeIcon className="text-gray-500" icon={faMobileAlt} />
                        </div>
                        <p className="flex-1 text-sm text-gray-500">
                            Your account requires two-factor authentication. To continue either open the authenticator app to get your authentication code or enter the code we just sent you either via Email or SMS.
                        </p>
                    </div>
                    
                </React.Fragment>
                
            )}
        </DisplayCard>
    );
};

export default LoginVerificationForm;