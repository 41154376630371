import wealthProApi from "../api";

const authEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        handshake: builder.mutation({
            query: ({ sessionId, code }) => ({
                url: '/handshake',
                method: 'POST',
                body: { sessionId, code }
            })
        }),
        login: builder.mutation({
            query: ({ username, password }) => ({
                url: '/login',
                method: 'POST',
                body: { username, password }
            })
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/logout',
                method: 'POST'
            })
        }),
        refresh: builder.mutation({
            query: () => ({
                url: '/session/refresh',
                method: 'POST'
            })
        }),
        registerAccount: builder.mutation({
            query: ({ emailAddress, welcomeCode, password }) => ({
                url: '/register',
                method: 'POST',
                body: { emailAddress, welcomeCode, password }
            })
        }),
        resetPassword: builder.mutation({
            query: ({ emailAddress, resetCode, password }) => ({
                url: '/reset',
                method: 'POST',
                body: { emailAddress, resetCode, password }
            })
        }),
        sendPasswordResetRequest: builder.mutation({
            query: ({ emailAddress }) => ({
                url: '/reset/code',
                method: 'POST',
                body: { emailAddress }
            })
        }),
        verifyCode: builder.mutation({
            query: ({ sessionId, verificationCode }) => ({
                url: '/session/verify',
                method: 'POST',
                body: { sessionId, verificationCode }
            })
        }),
        verifyMfa: builder.mutation({
            query: ({ sessionId }) => ({
                url: '/mfa/verify',
                method: 'POST',
                body: { sessionId }
            })
        }),
        verifyPasswordResetCode: builder.mutation({
            query: ({ emailAddress, resetCode }) => ({
                url: '/reset/verify',
                method: 'POST',
                body: { emailAddress, resetCode }
            })
        }),
        verifyWelcomeCode: builder.mutation({
            query: ({ emailAddress, welcomeCode }) => ({
                url: '/register/verify',
                method: 'POST',
                body: { emailAddress, welcomeCode }
            })
        })
    })
});

export const {
    useHandshakeMutation,
    useLoginMutation,
    useLogoutMutation,
    useRefreshMutation,
    useRegisterAccountMutation,
    useResetPasswordMutation,
    useSendPasswordResetRequestMutation,
    useVerifyCodeMutation,
    useVerifyMfaMutation,
    useVerifyPasswordResetCodeMutation,
    useVerifyWelcomeCodeMutation
} = authEndpoints;