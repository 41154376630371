import _ from "lodash";
import React from "react";
import WebsiteHero from "../components/WebsiteHero";
import useRegisterAccount from "../components/hooks/useRegisterAccount";
import useVerifyWelcomeToken from "../components/hooks/useVerifyWelcomeToken";
import { useForm, Controller } from "react-hook-form";
import { Navigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import clsx from "clsx";
import Button from "../components/Button";
import LoadingSpinner from "../features/portfoliovaluation/components/LoadingSpinner";
import FormInput from "../components/forms/FormInput";
import CompanyLogo from "../components/CompanyLogo";
import DisplayCard from "../components/DisplayCard";

const PasswordValidationListItem = ({ validation = {}, validationProperty = "", children }) => {
    return <li className={clsx({
        'text-red-500': !validation[validationProperty],
        'text-green-500': validation[validationProperty] === true 
    })}>
        <FontAwesomeIcon icon={!validation[validationProperty] ? faTimes : faCheck} />{' - '}{children}
    </li>
}

const PasswordForm = ({ registerErrors = [], displayName = '', isLoading = false, onConfirm = () => { }}) => {
    const [validation, setValidation] = React.useState({ inRange: false, hasUpper: false, hasLower: false, hasNumber: false, passwordsMatch: false });
    const isPasswordValidated = _.values(validation).every(e => e === true);
    const { control, formState: { errors }, handleSubmit, watch } = useForm({ defaultValues: { password: '', confirmPassword: '' } });
    const errorMessage = React.useMemo(() => {
        if (errors?.root?.verifyResult) {
            return errors?.root?.verifyResult ?? "There was a problem verifying your welcome code.";
        }
        if (registerErrors.length > 0) {
            return registerErrors[0].message;
        }
        if (errors?.emailAddress?.message) {
            return errors.emailAddress.message;
        }
        if (errors?.welcomeCode?.message) {
            return errors.welcomeCode.message;
        }
        return null;
    }, [errors, registerErrors]);

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const { password, confirmPassword } = value;
            let inRange = password.length >= 8 && password.length <= 50;
            let hasUpper = /[A-Z]/.test(password);
            let hasLower = /[a-z]/.test(password);
            let hasNumber = /[0-9]/.test(password);
            let passwordsMatch = password.length > 0 && confirmPassword.length > 0 && password == confirmPassword;
            setValidation(_ => {
                return {
                    inRange,
                    hasUpper,
                    hasLower,
                    hasNumber,
                    passwordsMatch
                };
            });
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <React.Fragment>
            <div className="text-sm text-gray-500 mx-2">
                <h1>Welcome{displayName && displayName.length > 0 ? `, ${displayName}.` : '.'}</h1>
                <br />
                <p>Your account is setup and ready to go. All you need to do is create a secure password for your account.</p>
            </div>
            <form className="flex flex-col gap-3 w-full my-4 border p-5" onSubmit={handleSubmit(onConfirm)}>
                {errorMessage && (
                    <div className="">
                        <p className="m-0 text-sm text-red-500">{errorMessage ?? "There was a problem with your request."}</p>
                    </div>
                )}
                <Controller 
                    name="password"
                    control={control}
                    rules={({ required: 'Password is required' })}
                    render={({ field: { ref, ...rest } }) => (
                        <FormInput 
                            pClassName="mb-0" 
                            label="Password" 
                            type="password"
                            maxLength={64}
                            disabled={isLoading}
                            autoComplete="new-password"
                            {...rest} 
                        />
                    )}
                />
                <Controller 
                    name="confirmPassword"
                    control={control}
                    rules={({ required: 'Password is required' })}
                    render={({ field: { ref, ...rest } }) => (
                        <FormInput 
                            pClassName="mb-0" 
                            label="Confirm Password" 
                            type="password"
                            maxLength={64}
                            disabled={isLoading}
                            autoComplete="new-password"
                            {...rest} 
                        />
                    )}
                />
                <ul className="px-2 py-1 text-sm">
                    <PasswordValidationListItem validation={validation} validationProperty="inRange">Password must be between 8 and 50 characters</PasswordValidationListItem>
                    <PasswordValidationListItem validation={validation} validationProperty="hasUpper">Must contain at least 1 upper case letter</PasswordValidationListItem>
                    <PasswordValidationListItem validation={validation} validationProperty="hasLower">Must contain at least 1 lower case letter</PasswordValidationListItem>
                    <PasswordValidationListItem validation={validation} validationProperty="hasNumber">Must contain at least 1 number</PasswordValidationListItem>
                    <PasswordValidationListItem validation={validation} validationProperty="passwordsMatch">Passwords must match</PasswordValidationListItem>
                </ul>
                <Button className="w-full text-center" type="submit" disabled={isLoading || !isPasswordValidated}>
                    {!isLoading ? (<>Register account</>) : (<LoadingSpinner width={3} height={3} />)}
                </Button>
            </form>
            
        </React.Fragment>
    );
};

const VerifyForm = ({ verifyErrors = [], isLoading = false, onConfirm = () => { } }) => {
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues: { emailAddress: '', welcomeCode: '' } });
    const errorMessage = React.useMemo(() => {
        if (errors?.root?.verifyResult) {
            return errors?.root?.verifyResult ?? "There was a problem verifying your welcome code.";
        }
        if (verifyErrors.length > 0) {
            return verifyErrors[0].message;
        }
        if (errors?.emailAddress?.message) {
            return errors.emailAddress.message;
        }
        if (errors?.welcomeCode?.message) {
            return errors.welcomeCode.message;
        }
        return null;
    }, [errors, verifyErrors]);

    return (
        <React.Fragment>
            <div className="text-sm text-gray-500 mx-2">
                <h1>Welcome to WealthPro, Save & Invest's online client portfolio service.</h1>
                <br />
                <p>Please enter your email address and welcome code that your adviser setup for you below. You will then be allowed to create a password and gain access to your account.</p>
            </div>
            <form className="flex flex-col gap-3 w-full my-4 border p-5" onSubmit={handleSubmit(onConfirm)}>
                {errorMessage && (
                    <div className="">
                        <p className="m-0 text-sm text-red-500">{errorMessage ?? "There was a problem with your request."}</p>
                    </div>
                )}
                <Controller 
                    name="emailAddress"
                    control={control}
                    rules={({ required: 'Email address is required.', maxLength: 2048 })}
                    render={({ field: { ref, ...rest } }) => (
                        <FormInput 
                            pClassName="mb-0" 
                            label="Email Address" 
                            type="email"
                            maxLength={2048}
                            disabled={isLoading}
                            autoComplete="email"
                            {...rest} 
                        />
                    )}
                />
                <Controller 
                    name="welcomeCode"
                    control={control}
                    rules={({ required: 'Welcome code is required.', maxLength: 32 })}
                    render={({ field: { ref, ...rest } }) => (
                        <FormInput 
                            pClassName="mb-2" 
                            label="Welcome Code" 
                            type="text"
                            maxLength={32}
                            disabled={isLoading}
                            autoComplete="off"
                            {...rest} 
                        />
                    )}
                />
                <Button className="w-full text-center" type="submit" disabled={isLoading}>
                    {!isLoading ? (<>Continue</>) : (<LoadingSpinner width={3} height={3} />)}
                </Button>
            </form>
        </React.Fragment>
    );
};

const WelcomeRegisterPage = () => {
    const [searchParams] = useSearchParams();
    const [isVerified, setIsVerified] = React.useState(false);
    const [verifyErrors, setVerifyErrors] = React.useState([]);
    const [registerErrors, setRegisterErrors] = React.useState([]);
    const [displayName, setDisplayName] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState(searchParams.get('emailAddress'));
    const [welcomeCode, setWelcomeCode] = React.useState(searchParams.get('welcomeCode')); 
    const { verify, isLoading } = useVerifyWelcomeToken();
    const { register, isLoading: isRegistering } = useRegisterAccount();

    const _register = ({ password }) => {
        if (!isVerified) {
            return;
        }
        register(emailAddress, welcomeCode, password).then(
            r => {
                window.location.href = `/signin?registered=true`;
            },
            e => {
                setRegisterErrors(errors => {
                    return [...errors, { ...e.data }];
                }); 
            }
        );
    };

    const _verify = ({ emailAddress, welcomeCode }) => {
        verify(emailAddress, welcomeCode).then(
            r => {
                setIsVerified(_ => true);
                setWelcomeCode(_ => welcomeCode);
                setEmailAddress(_ => emailAddress);
                setDisplayName(_ => r?.displayName);
            },
            e => {
                setVerifyErrors(errors => {
                    return [...errors, { ...e.data }];
                });  
            }
        );
    };

    React.useEffect(() => {
        if (!searchParams.has('emailAddress') || !searchParams.has('welcomeCode')) {
            return;
        }
        _verify({
            emailAddress: searchParams.get('emailAddress'),
            welcomeCode: searchParams.get('welcomeCode')
        });
    }, [searchParams]);

    return (
        <WebsiteHero>
            <DisplayCard className="bg-white text-black p-4 mx-4 lg:mx-0 max-w-xl">
                {isLoading && (
                    <div className="flex justify-center items-center text-center">
                        <LoadingSpinner label="Verifying welcome code..." />
                    </div>
                )}
                {!isLoading && (
                    <React.Fragment>
                        <div className="flex flex-col justify-center items-center p-3 mb-1">
                            <CompanyLogo />
                        </div>
                        {isVerified && (
                            <PasswordForm registerErrors={registerErrors} displayName={displayName} isLoading={isRegistering} onConfirm={_register} />
                        )}
                        {!isVerified && (
                            <VerifyForm verifyErrors={verifyErrors} isLoading={isLoading} onConfirm={_verify} />
                        )}
                    </React.Fragment>
                )}
            </DisplayCard>
        </WebsiteHero>
    );
};

export default WelcomeRegisterPage;