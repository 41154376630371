import React, { useState } from "react";
import Drawer from 'react-modern-drawer';
import CompanyLogo from "./CompanyLogo";
import WealthProLogo from "./WealthProLogo";
import WealthProText from "./WealthProText";
import useWebsiteNavigation from "./WebsiteNavigationContext";
import { useSession } from "./SessionContext";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";
import useLogout from "./hooks/useLogout";

export const WebsiteNavigationDrawer = ({ direction = "left", isOpen, toggleDrawer }) => {
    const session = useSession();
    const logout = useLogout();

    const { sitemap } = useWebsiteNavigation();
    const { user: { name, ref } } = session;

    return (
        <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction={direction}
            size="66%"
            className="overflow-x-auto"
        >
            <div className="sticky top-0 border-b border-slate-300 bg-white z-10 p-3 mb-2">
                <div className="flex justify-center mb-2">
                    <CompanyLogo className="w-48" />
                </div>
                <div className="flex flex-col justify-start text-left text-slate-600">
                    <span className="text-sm">Hi, {name}</span>
                    <span className="text-xs text-slate-400">Account Reference: {ref}</span>
                </div>
            </div>
            {sitemap.map((el, i) => (
                <div key={i} className="px-3">
                    <p className="text-xs mb-2 font-bold uppercase text-slate-400">{el.title}</p>
                    <div className="flex flex-col text-slate-700">
                        {el.menu.map((el, j) => (
                            <NavLink 
                                key={`${i}-${j}`} 
                                to={el.to} 
                                onClick={toggleDrawer} 
                                className={({ isActive }) => isActive ? "text-sm mb-3 px-5 py-3 bg-slate-100" : "text-sm mb-3 px-5 py-3 hover:bg-slate-100"}
                            >
                                <FontAwesomeIcon icon={el.icon} className={`me-3 ${el.iconClass ? el.iconClass : ''}`} />
                                <span>{el.title}</span>
                            </NavLink>
                        ))}
                    </div>
                </div>
            ))}
            <div className="px-3">
                <p className="text-xs mb-2 font-bold uppercase text-slate-400">My Profile</p>
                <div className="flex flex-col text-slate-700">
                    <div onClick={logout} className="text-sm mb-3 px-5 py-3 cursor-pointer hover:bg-slate-100">
                        <FontAwesomeIcon icon="fa-right-from-bracket" className={`me-3 text-red-300`} />
                        <span>Log Out</span>
                    </div>
                </div>
            </div>
            <div className="inline-flex items-center p-4 border-t border-slate-300">
                <WealthProLogo className="w-12 h-10 mr-3"  />
                <WealthProText />
            </div>
        </Drawer>
    );
};

export const WebsiteNavigationMenu = ({ children, placement = "bottom-start", title }) => {
    const [referenceElement, setReferenceElement] = useState();
    const [popperElement, setPopperElement] = useState();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: placement
    });

    return (
        <Popover>
            <Popover.Button ref={setReferenceElement} className="text-xs md:text-sm lg:text-lg mr-4">
                <span className="mr-1">{title}</span>
                <FontAwesomeIcon icon="fa-chevron-down" size="xs" className="transition ease-in-out duration-500 ui-open:rotate-180 ui-open:transform" />
            </Popover.Button>
            <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                className="bg-white text-black shadow-md p-4 mt-5 lg:mt-4 w-2/6 rounded grid grid-cols-1"
                {...attributes.popper}
            >
                {({ close }) => (
                    React.Children.map(children, child => (
                        React.cloneElement(child, { close })
                    ))
                )}
            </Popover.Panel>
        </Popover>
    );
};

export const WebsiteNavigationMenuItem = ({ close, icon, iconClass, text, title, ...rest }) => {
    
    return (
        <NavLink 
            onClick={close}
            className={({ isActive }) => isActive 
                ? "w-full flex p-2 cursor-pointer border rounded border-gray-200 bg-gray-200"
                : "w-full flex p-2 cursor-pointer border rounded border-white hover:border-gray-200 hover:shadow-inner" 
            }
            {...rest}
        >
            <div className="flex align-top justify-center items-start p-2 w-9">
                <FontAwesomeIcon icon={icon} className={`text-lg leading-none lg:text-2xl ${iconClass ? iconClass : ''}`} />
            </div>
            <div className="flex flex-col p-2">
                <span className="text-xs leading-none mb-1 lg:text-base">{title}</span>
                <p className="text-[0.6rem] leading-tight text-zinc-400 lg:text-xs">{text}</p>
            </div>
        </NavLink>
    );
};

const WebsiteNavigation = () => {
    const { sitemap } = useWebsiteNavigation();

    return (
        <nav className="hidden bg-gradient-to-r from-blue-900 via-indigo-700 to-indigo-600 text-white px-6 py-3 md:flex print:hidden md:print:hidden flex-row justify-between sticky top-16 z-10 max-h-16">
            <ul className="inline-flex flex-wrap text-sm leading-5 p-1">
                {sitemap.map((el, i) => (
                    <WebsiteNavigationMenu key={i} title={el.title} placement={el.placement ?? 'bottom-start'}>
                        {el.menu.map(({ requiredRoles, ...rest }, j) => (
                            <WebsiteNavigationMenuItem key={`${i}-${j}`} {...rest} />
                        ))}
                    </WebsiteNavigationMenu>
                ))}
            </ul>
        </nav>
    );
};

export default WebsiteNavigation;

/*
                    <NavLink 
                        to="/settings"
                        onClick={toggleDrawer} 
                        className={({ isActive }) => isActive ? "text-sm mb-3 px-5 py-3 bg-slate-100" : "text-sm mb-3 px-5 py-3 hover:bg-slate-100"}
                    >
                        <FontAwesomeIcon icon="fa-cogs" className={`me-3 text-green-300`} />
                        <span>User Settings</span>
                    </NavLink>
*/