import React from "react";
import Accordion from "../../components/Accordion";
import DocumentsList from "./components/DocumentsList";

export const DocumentsLoader = () => {
    return (
        <React.Fragment>
            <div className="animate-pulse bg-slate-300 w-full h-12 text-left border border-slate-300 rounded mb-3" />
            <div className="animate-pulse bg-slate-300 w-full h-12 text-left border border-slate-300 rounded mb-3" />
            <div className="animate-pulse bg-slate-300 w-full h-12 text-left border border-slate-300 rounded mb-3" />
        </React.Fragment>
    );
};

const Documents = ({ dates = [] }) => {
    return dates.map((el, i) => (
        <div key={i} className="mb-3">
            <Accordion title={(
                <div className="flex flex-1 justify-between items-center me-3">
                    <span>Year: {el.year}</span>
                    <span>{el.totalDocuments} document(s)</span>
                </div>
            )}>
                <DocumentsList slug={el.slug} year={el.year} />
            </Accordion>
        </div>
    ));
};

export default Documents;