import React from "react";
import usePortfolioInvestmentsByOwner from "../hooks/usePortfolioInvestmentsByOwner";
import Money from "../../../components/Money";
import LoadingSkeleton from "./LoadingSkeleton";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import PortfolioValuationInvestmentSummaryWrapper from "./PortfolioValuationInvestmentsSummaryWrapper";

const PortfolioValuationInvestmentSummaryByOwners = () => {

    const { data, error, isFetching, isLoading } = usePortfolioInvestmentsByOwner();

    if(isLoading || isFetching){
        return(
            <PortfolioValuationInvestmentSummaryWrapper>
                <LoadingSkeleton />
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(!error && data == null || data.length <= 0){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full">
                    <table className="w-full table-fixed p-6">
                        <thead>
                            <tr className="text-blue-900">
                                <th className="md:w-[70%]"></th>
                                <th className="text-right">Value</th>
                                <th className="text-right">Allocation</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-x-auto text-zinc-600">
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if(error){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full text-center">
                    <FetchErrorMessage />
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }     

    return (
        <>
            {data != null && data.length > 0 && data.map((el, i) => (
                <div key={i} className="mb-3">
                    <PortfolioValuationInvestmentSummaryWrapper type="accordion" title={el.description} value={el.totalFundValues} allocation={el.totalAllocation}>
                        <table className="w-full table-fixed p-6 text-zinc-600 text-xs lg:text-base">
                            <thead>
                                <tr className="text-blue-900">
                                <th className="md:w-[70%]"></th>
                                    <th className="text-right">Value</th>
                                    <th className="text-right">Allocation</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-x-auto">
                                {el.subGroupings.length > 0 && el.subGroupings.map((c) => (
                                    <>
                                        <tr>
                                            <td colspan="3" className="font-bold truncate">{c.description}</td>
                                        </tr>

                                        {c.investments.length > 0 && c.investments.map((inv) => (
                                            <tr>
                                                <td className="truncate">{inv.investmentName}</td>
                                                <td className="text-right tabular-nums"> <Money prefix={'£'} value={inv.value} showNil={false} /></td>
                                                <td className="text-right tabular-nums">{inv.allocation}%</td>
                                            </tr>
                                        ))}

                                        <tr className="">
                                            <td className="w-[100%] font-bold"></td>
                                            <th className="text-right tabular-nums"><Money prefix={'£'} value={c.totalFundValues} showNil={false} /></th>
                                            <th className="text-right tabular-nums">{c.totalAllocation}%</th>
                                        </tr>
                                    </>
                                ))}           
                            </tbody>
                        </table>
                    </PortfolioValuationInvestmentSummaryWrapper>
                </div>
            ))}
        </>
    )
}

export default PortfolioValuationInvestmentSummaryByOwners;