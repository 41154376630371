import React from "react";
import App from "./App";
import { DocumentSlugRouteAuthRoleGuard, RouteAuthRoleGuard, RouteSwitchGuard } from "./components/ProtectedRoute";
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from "react-router-dom";
import {
	DocumentsPage,
	DocumentsSignPage,
	ForgotPasswordPage,
	HandshakePage,
	HomePage,
	NotAuthorisedPage,
	NotFoundPage,
	PersonalMessagesPage,
	PersonalNotificationsPage,
	PortfolioProfilePage,
	PortfolioSummaryPage,
	PortfolioValuationPage,
	PortfolioChangesPage,
	FundCommentaryPage,
	PortfolioPerformancePage,
	OurViewPage,
	ResetPasswordPage,
	SignInPage,
	SignInVerificationPage,
	WelcomeRegisterPage
} from "./pages";

const router = createBrowserRouter([
	{ path: '/', element: <HomePage />, errorElement: <NotFoundPage /> },
	{ path: '/404', element: <NotFoundPage /> },
	{ path: '/unauthorised', element: <NotAuthorisedPage /> },
	{ path: '/signin', element: <SignInPage /> },
	{ path: '/verify', element: <SignInVerificationPage /> },
	{ path: '/welcome', element: <WelcomeRegisterPage /> },
	{ path: '/forgot-password', element: <ForgotPasswordPage /> },
	{ path: '/reset-password', element: <ResetPasswordPage /> },
	{ path: '/handshake', element: <HandshakePage /> },
	{ 
		path: '/dashboard',
		errorElement: (<NotFoundPage />),
		element: (<App />),
		children: [
			{ path: '', element: (<RouteSwitchGuard route="portfolio" redirect="our-view" requiredRoles={["portfolio_access"]} />) },
			{
				path: 'our-view',
				element: (<OurViewPage />)
			},
			{
				path: 'portfolio',
				children: [
					{ path: '', element: (<Navigate to="summary" />) },
					{
						path: 'summary',
						element: (
							<RouteAuthRoleGuard>
								<PortfolioSummaryPage />
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'changes',
						element: (
							<RouteAuthRoleGuard>
								<PortfolioChangesPage />
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'profile',
						element: (
							<RouteAuthRoleGuard>
								<PortfolioProfilePage />
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'valuation',
						element: (
							<RouteAuthRoleGuard>
								<PortfolioValuationPage />
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'performance',
						element: (
							<RouteAuthRoleGuard>
								<PortfolioPerformancePage />
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'fund-commentary',
						element: (
							<RouteAuthRoleGuard>
								<FundCommentaryPage />
							</RouteAuthRoleGuard>
						)
					}
				]
			},
			{
				path: 'documents',
				children: [
					{
						path: 'sign',
						element: (<DocumentsSignPage />)
					},
					{
						path: ':slug',
						element: (
							<DocumentSlugRouteAuthRoleGuard>
								<DocumentsPage />
							</DocumentSlugRouteAuthRoleGuard>
						)
					}
				]
			},
		]
	}
]);

const Root = () => <RouterProvider router={router} />

export default Root;

/*
			// { 
			// 	path: 'markets', 
			// 	element:(<h1>Market News</h1>) 
			// },
			// { 
			// 	path: 'articles',
			// 	children: [
			// 		{ path: '', element: (<h1>Articles</h1>) },
			// 		{ path: ':slug', element: (<h1>Article Reader Page</h1>) }
			// 	]
			// },

						{
				path: 'personal',
				children: [
					{ path: '', element: (<Navigate to="notifications" />) },
					{
						path: 'notifications',
						element: (
							<RouteAuthRoleGuard>
								<PersonalNotificationsPage />
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'fact-find',
						element: (
							<RouteAuthRoleGuard>
								<h1>Fact Find Editor</h1>
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'appointments',
						element: (
							<RouteAuthRoleGuard>
								<h1>Appointments</h1>
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'messages',
						element: (
							<RouteAuthRoleGuard>
								<PersonalMessagesPage />
							</RouteAuthRoleGuard>
						)
					},
					{
						path: 'video-meetings',
						element: (
							<RouteAuthRoleGuard>
								<h1>Video Meetings</h1>
							</RouteAuthRoleGuard>
						)
					}
				]
			},
			{
				path: 'tools',
				element: (
					<RouteAuthRoleGuard>
						<Outlet />
					</RouteAuthRoleGuard>
				),
				children: [
					{ path: 'planning-timeline', element: (<h1>Planning Timeline</h1>) },
					{ path: 'investment-products', element: (<h1>Investment Products</h1>) },
					{ path: 'income-and-expenses', element: (<h1>Income and Expenses</h1>) },
					{ path: 'regular-savings', element: (<h1>Regular Savings</h1>) },
					{ path: 'withdrawals', element: (<h1>Withdrawals</h1>) },
					{ path: 'inflation-growth-rates', element: (<h1>Inflation and Growth Rates</h1>) },
					{ path: 'cash-flow-results', element: (<h1>Cash Flow Results</h1>) }
				]
			}
*/