import { useRegisterAccountMutation } from "../../redux";

const useRegisterAccount = () => {
    const [_register, { isLoading, ...registerData }] = useRegisterAccountMutation();

    const register = (emailAddress, welcomeCode, password) => {
        return new Promise((resolve, reject) => {
            return _register({ emailAddress, welcomeCode, password })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { register, isLoading, data: { isLoading, ...registerData } };
};

export default useRegisterAccount;