import { useGetDashboardCommentQuery } from "../../../redux";

const useAdviserComment = () => {

    const data = useGetDashboardCommentQuery();

    return {
        ...data || ""
    };
};

export default useAdviserComment;