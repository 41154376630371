import React from "react";
import Modal from "react-modal";
import FundChartDisplay from "./FundChartDisplay";
import StandardTable from "../../../components/StandardTable";
import Percentage from "../../../components/Percentage";
import FundCommentaryHtmlSection from "./FundCommentaryHtmlSection";
import useFundFactSheet from "../hooks/useFundFactSheet";
import Skeleton from "../../../components/Skeleton";
import { IconButton } from "../../../components/Button";

const FundCommentaryModalContent = ({data, error, isFetching, isLoading}) => {
    
    if(isLoading){
        return (
            <>
                <Skeleton height="h-4" />
                <Skeleton height="h-4" className="my-1" />
                <Skeleton height="h-4" />
            </>
        );
    }

    if(error){
        return <p>There was a problem fetching the information for this fact sheet.</p>;
    }

    if(data != null){
        return (
            <>
                  {data.investmentRationale != null && data.investmentRationale != "" && <FundCommentaryHtmlSection html={data.investmentRationale} />}

                    {data.fundChart != null && data.fundChart !="" && <FundChartDisplay chartData={data.fundChart}/>}

                    {data.annualisedTotalReturn.length > 0 && <StandardTable 
                        className="md:border-spacing-1 my-4 text-blue-700 whitespace-nowrap overflow-x-auto"
                        columns={[
                            { 
                                label: 'Annualised Total Return', 
                                property: 'fundName',
                                headerClassName:"text-fuchsia-700"
                            },
                            { 
                                label: '1 Month',  
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'totalReturn1Month',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.totalReturn1Month} decimalPlaces={2} />
                                        </td>
                                    );
                                }
                            },
                            { 
                                label: '3 Months',  
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'totalReturn3Month',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.totalReturn3Month} decimalPlaces={2} />
                                        </td>
                                    );
                                }
                            },
                            { 
                                label: '6 Months',  
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'totalReturn6Month',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.totalReturn6Month} decimalPlaces={2} />
                                        </td>
                                    );
                                }
                            },
                            { 
                                label: '1 Year',  
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'totalReturn12Month',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.totalReturn12Month} decimalPlaces={2} />
                                        </td>
                                    );
                                }
                            },
                            { 
                                label: '3 Years',  
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'averageTotalReturn3Years',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.averageTotalReturn3Years} decimalPlaces={2} />
                                        </td>
                                    );
                                }
                            },
                            { 
                                label: '5 Years',  
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'averageTotalReturn5Years',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.averageTotalReturn5Years} decimalPlaces={2} />
                                        </td>
                                    );
                                }
                            },
                        ]}
                        data={data.annualisedTotalReturn}
                    />}

                    {data.historicalVolatility && <StandardTable 
                        className="md:border-spacing-1 my-4 text-blue-700 whitespace-nowrap overflow-x-auto"
                        columns={[
                            { 
                                label: 'Historical Volatility (3 Years)', 
                                headerClassName: 'text-fuchsia-700',
                                property: 'fundName' 
                            },
                            { 
                                label: 'Volatility', 
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'volatility',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.volatility} decimalPlaces={2}  />
                                        </td>
                                    );
                                }
                            },
                            { 
                                label: 'Max Drawdown', 
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'maxDrawdown',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.maxDrawdown} decimalPlaces={2}  />
                                        </td>
                                    );
                                } 
                            },
                            { 
                                label: 'Max Gain', 
                                headerClassName: 'text-right text-fuchsia-700',
                                property: 'maxGain',
                                renderer: ({ key, data }) => {
                                    return (
                                        <td key={key} className="text-right tabular-nums">
                                            <Percentage value={data.maxGain} decimalPlaces={2}  />
                                        </td>
                                    );
                                } 
                            },
                        ]}
                        data={data.historicalVolatility}
                    />}

                    {data.currentOpinion != null && data.currentOpinion != "" && <FundCommentaryHtmlSection html={data.currentOpinion} />}

                    <div className="container mx-auto grid grid-cols-1 gap-4 md:grid-cols-2">
                        <div className="flex flex-col">
                            {data.assetAllocation.length > 0 && <StandardTable 
                                className="md:border-spacing-1 text-blue-700 whitespace-nowrap overflow-x-auto"
                                columns={[
                                    { 
                                        label: 'Assets', 
                                        property: 'description',
                                        headerClassName:'text-fuchsia-700'
                                    },
                                    { 
                                        label: 'Allocation', 
                                        headerClassName: 'text-right text-fuchsia-700',
                                        property: 'weighting',
                                        renderer: ({ key, data }) => {
                                            return (
                                                <td key={key} className="text-right tabular-nums">
                                                    <Percentage value={data.weighting} decimalPlaces={2} />
                                                </td>
                                            );
                                        }
                                    }
                                ]}
                                data={data.assetAllocation}
                            />}
                        </div>
                        <div className="flex flex-col">
                            {data.regionAllocation.length > 0 && <StandardTable 
                                className="md:border-spacing-1 text-blue-700 whitespace-nowrap overflow-x-auto"
                                columns={[
                                    { 
                                        label: 'Regions', 
                                        property: 'description',
                                        headerClassName:'text-fuchsia-700' 
                                    },
                                    { 
                                        label: 'Allocation', 
                                        headerClassName: 'text-right text-fuchsia-700',
                                        property: 'weighting',
                                        renderer: ({ key, data }) => {
                                            return (
                                                <td key={key} className="text-right tabular-nums">
                                                    <Percentage value={data.weighting} decimalPlaces={2}  />
                                                </td>
                                            );
                                        }  
                                    }
                                ]}
                                data={data.regionAllocation}
                            />}
                        </div>
                    </div>

                    {data.aboutTheFundManager != null && data.aboutTheFundManager != "" && <FundCommentaryHtmlSection html={data.aboutTheFundManager}  header="About The Asset Manager" headerClass="bg-fuchsia-700"/>}
            </>
        )
    }
};

const FundCommentaryInfoModal = ({
    title = "",
    assetId,
    assetGroupId,
    fundName,
    isModalOpen,
    setIsModalOpen,
}) => {

    const { data, error, isFetching, isLoading } = useFundFactSheet(assetGroupId, assetId);

    return (
        <React.Fragment>
            <Modal
                isOpen={isModalOpen}
                contentLabel={`{title}-dialog`}
                onRequestClose={() => setIsModalOpen(false)}
                shouldCloseOnOverlayClick={false}
                className="mt-4 flex items-center justify-center w-[100%]"
            >
                <div className="absolute top-[110px] w-[95%] bg-white m-5 border-2 rounded-md border-blue-900 md:w-[80%] lg:w-[60%] xl:w-[60%]">
                    <div className="w-full bg-gradient-to-r from-blue-900 via-indigo-700 to-indigo-600 border-blue-900 text-white p-4 rounded-t">
                        <div className="grid grid-cols-12 w-full">
                            <div className="col-span-9 float-left mt-auto mb-auto">{fundName}</div>
                            <div className="col-span-2 m-auto">{data != null && data.volatility}</div>
                            <div className="col-span-1 text-end"><IconButton icon="fa-solid fa-x" iconSize="lg" buttonClassName="bg-transparent" onClick={() => setIsModalOpen(false)}/></div>
                        </div>
                    </div>
                    <div className="w-full px-2 p-4 bg-white rounded-b-md max-h-[calc(100vh-250px)] overflow-y-scroll">
                        <div className="bg-white p-2 md:px-8 pt-0 rounded-b">
                            <FundCommentaryModalContent data={data} error={error} isLoading={isLoading} />
                        </div>
                    </div> 
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default FundCommentaryInfoModal;
