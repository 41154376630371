import React from "react";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import OurView from "../features/ourview";

const OurViewPage = () => {
    return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader>Our View</WebsitePageHeader>
                <OurView />
            </div>
        </React.Fragment>
    );
};

export default OurViewPage;
