import React from "react";
import Button from "../Button";
import FormInput from "./FormInput";
import CompanyLogo from "../CompanyLogo";
import DisplayCard from "../DisplayCard";
import LoadingSpinner from "../../features/portfoliovaluation/components/LoadingSpinner";
import useLoginVerification from "../hooks/useLoginVerification";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

const LoginVerificationRegisterForm = ({
    sessionId,
    mfaType,
    emailAddress = null,
    otpUri = null,
    otpSecureKey = null,
    otpImage = null
}) => {
    const { verify, isLoading } = useLoginVerification();
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues: { emailAddress, otpUri, otpSecureKey, verificationCode: '' } });

    const _submit = ({ verificationCode }) => {
        verify(sessionId, verificationCode)
            .then(
                _ => { },
                e => {
                    const { message, statusCode } = e.data;
                    reset({ emailAddress, otpUri, otpSecureKey, verificationCode: '' });
                    setError("root.authResult", { type: statusCode, message });
                }
            );
    };

    if (!sessionId || !mfaType) {
        return null;
    }

    return (
        <DisplayCard className="bg-white text-black p-4 mx-4 lg:mx-0 max-w-xl">
            <div className="flex flex-col justify-center items-center p-3">
                <CompanyLogo />
            </div>
            <div className="flex flex-col justify-center items-center">
                <h1 className="text-xl text-gray-500 font-bold">Two-factor authentication required</h1>
            </div>
            <div className="flex gap-x-2 my-5">
                <div>
                    <FontAwesomeIcon className="text-gray-500" icon={faMobileAlt} />
                </div>
                <p className="flex-1 text-sm text-gray-500">
                    Your account requires two-factor authentication to be configured. Your adviser has configured your preferences to use time based two-factor authentication (TOTP). Please scan the QR Code below with your preferred authenticator app and enter the 6-digit code it provides to continue.
                </p>
            </div>
            <form className="flex flex-col gap-3 w-full border p-5" onSubmit={handleSubmit(_submit)}>
                {errors?.root?.authResult && (
                    <div className="">
                        <p className="m-0 text-sm text-red-500">{errors?.root?.authResult?.message ?? "There was a problem with your request."}</p>
                    </div>
                )}
                <div className="w-full">
                    <Controller 
                        name="emailAddress"
                        control={control}
                        rules={({ required: 'Email address is required', minLength: 0, maxLength: 2048 })}
                        render={({ field: { ref, ...rest } }) => (
                            <FormInput 
                                type="email" 
                                pClassName="mb-2"
                                label="Email address" 
                                maxLength={2048} 
                                disabled={true}
                                autoComplete="off" 
                                {...rest} 
                            />
                        )}
                    />
                    <Controller 
                        name="otpSecureKey"
                        control={control}
                        rules={({ required: 'Secure key is required', minLength: 0, maxLength: 255 })}
                        render={({ field: { ref, ...rest } }) => (
                            <FormInput 
                                type="text" 
                                pClassName="mb-2"
                                label="Secret Key" 
                                maxLength={2048} 
                                disabled={true}
                                autoComplete="off" 
                                {...rest} 
                            />
                        )}
                    />
                    <Controller 
                        name="otpUri"
                        control={control}
                        rules={({ required: 'Otp URI is required', minLength: 0, maxLength: 2048 })}
                        render={({ field: { ref, ...rest } }) => (
                            <FormInput 
                                type="text" 
                                pClassName="mb-2"
                                label="Uri" 
                                maxLength={2048} 
                                disabled={true}
                                autoComplete="off" 
                                {...rest} 
                            />
                        )}
                    />
                    <div className="flex justify-center py-3">
                        <img src={otpImage} alt={otpSecureKey} className="w-48" />
                    </div>
                    <Controller 
                        name="verificationCode"
                        control={control}
                        rules={({ required: 'Verification code required', minLength: 6, maxLength: 6 })}
                        render={({ field: { ref, ...rest } }) => (
                            <FormInput 
                                type="tel" 
                                pClassName="mb-0"
                                label="Verification Code" 
                                maxLength={6} 
                                disabled={isLoading}
                                autoComplete="one-time-code" 
                                {...rest} 
                            />
                        )}
                    />
                </div>
                <Button className="w-full text-center" type="submit" disabled={isLoading}>
                    {!isLoading ? (<>Verify</>) : (<LoadingSpinner width={3} height={3} />)}
                </Button>
            </form>
        </DisplayCard>
    );
};

export default LoginVerificationRegisterForm;