
import { useGetInvestmentsByVolatilitySummaryQuery } from "../../../redux";

const usePortfolioInvestmentsByVolatility = () => {
    
    const data = useGetInvestmentsByVolatilitySummaryQuery();

    return {
       ...data
    };
};

export default usePortfolioInvestmentsByVolatility;