import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './Root';
import SessionProvider from "./components/SessionContext";
import WebsiteCookieConsent from "./components/WebsiteCookieConsent";
import {} from './helpers/array';
import { Toaster } from "react-hot-toast";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import 'preline/preline';

// configure styles & icons
import './index.css';
import './css/forms.css';
import 'react-modern-drawer/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
library.add(fas);

// NOTE: we use the tenenant provider now to collect the oidc & theme data from the back-end
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<SessionProvider>
		<Root />
		<WebsiteCookieConsent />
		<Toaster position='top-right' />
	</SessionProvider>
);