import { useVerifyMfaMutation } from "../../redux";

const useVerifyMfaCode = () => {
    const [_verify, { isLoading, ...verifyData }] = useVerifyMfaMutation();

    const verify = (sessionId) => {
        return new Promise((resolve, reject) => {
            return _verify({ sessionId })
                .unwrap()
                .then(
                    r => {
                        return resolve(r);
                    },
                    e => {
                        return reject(e);
                    }
                )
        });
    };

    return { verify, isLoading, data: { isLoading, ...verifyData } };
};

export default useVerifyMfaCode;