import React from "react";

export const WebsitePageSubHeader = ({ children, isLoading = false, ...rest }) => {
    if (isLoading === true) {
        return <div className="animate-pulse h-7 bg-slate-300 rounded w-1/4 my-5" />
    }

    return (
        <h2 className="text-xl" {...rest}>{children}</h2>
    );
};

const WebsitePageHeader = ({ children, isLoading = false, ...rest }) => {
    if (isLoading === true) {
        return <div className="animate-pulse h-8 bg-slate-300 rounded w-1/3 my-5" />
    }

    return (
        <h1 className="text-2xl text-blue-900 font-bold my-5" {...rest}>{children}</h1>
    );
};

export default WebsitePageHeader;