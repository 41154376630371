import wealthProApi from "../api";

const factfindEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        getDashboardComment: builder.query({
            query: () => 'api/factfind/adviser-note'
        })
    })
});

export const {
    useGetDashboardCommentQuery,
} = factfindEndpoints;