import wealthProApi from "../api";

const investmentperspectivesEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        getOurView: builder.query({
            query: () => `api/investmentperspectives/our-view`
        })
    })
});

export const {
    useGetOurViewQuery,
} = investmentperspectivesEndpoints;