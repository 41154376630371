import React from "react";
import ChartDisplayCard from "./components/ChartDisplayCard";
import ProfileComment from "./components/ProfileComment";
import usePortfolioProfile from "./hooks/usePortfolioProfile";
import useAdviserComment from "./hooks/useAdviserComment";

const PortfolioProfile = () => {
    const { data : profile, error: proflieError, isFetching : profileIsFetching, isLoading : profileIsLoading } = usePortfolioProfile();
    const { data : comment, error : commentError, isFetching : commentIsFetching, isLoading : commentIsLoading } = useAdviserComment();

    const { diversificationChart, volatilityChart } = profile || { diversificationChart: null, volatilityChart: null };
    const { adviserComment } = comment || { adviserComment: null };
    
    return (
        <React.Fragment>
            <ProfileComment 
                data={adviserComment} 
                error={commentError}
                isLoading={commentIsFetching || commentIsLoading}
                />
            <div className="grid gap-2 grid-cols-1 auto-cols-max md:grid-cols-2 md:gap-3 my-2 md:my-3">
                {volatilityChart != null && volatilityChart.data.length > 0 &&
                    <ChartDisplayCard 
                        title="Portfolio Volatility"
                        inputData={profileIsLoading ? null : volatilityChart}
                        error={proflieError} 
                        isLoading={profileIsFetching || profileIsLoading} 
                    />
                }
                {diversificationChart != null && diversificationChart.data.length > 0 &&
                    <ChartDisplayCard 
                        title="Asset Class"
                        inputData={profileIsLoading ? null : diversificationChart} 
                        error={proflieError} 
                        isLoading={profileIsFetching || profileIsLoading} 
                    />
                }
            </div>
        </React.Fragment>
    );
};

export default PortfolioProfile;