import React from "react";
import SummaryWrapper from "./SummaryWrapper";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import LoadingSkeleton from "./LoadingSkeleton";
import Money from "../../../components/Money";
import usePerformanceProductSummary from "../hooks/usePerformanceProductSummary";
import Percentage from "../../../components/Percentage";
import Moment from "react-moment";

const PortfolioPerformanceSummaryByProduct = () => {

    const { data, error, isFetching, isLoading } = usePerformanceProductSummary();

    if(isLoading || isFetching){
        return(
            <SummaryWrapper>
                <LoadingSkeleton />
            </SummaryWrapper>
        );
    }

    if(!error && data == null || data.length <= 0){
        return (
            <SummaryWrapper>
                <div className="flex flex-col w-full">
                    <table className="w-full border-separate border-spacing-x-2 table-fixed text-zinc-600">
                        <thead>
                            <tr className="text-blue-900">
                                <th className="md:w-[50%]">Fund</th>
                                <th className="text-right">Invested</th>
                                <th className="text-right">Amount</th>
                                <th className="text-right">Value</th>
                                <th className="text-right">Gain</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-x-auto">
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </SummaryWrapper>
        );
    }

    if(error){
        return (
            <SummaryWrapper>
                <div className="flex flex-col w-full text-center">
                    <FetchErrorMessage />
                </div>
            </SummaryWrapper>
        );
    }     

    return (
        <>
            {data != null && data.length > 0 && data.map((el, i) => (
                <div key={i} className="mb-3">
                    <SummaryWrapper type="accordion" title={el.description} value={el.totalFundValues}>
                        <table className="w-full table-fixed p-6 text-zinc-600">
                            <thead>
                                <tr className="text-blue-900">
                                    <th className="md:w-[50%]"></th>
                                    <th className="text-right">Date Invested</th>
                                    <th className="text-right">Amount Invested</th>
                                    <th className="text-right">Current Value</th>
                                    <th className="text-right">Gain</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-x-auto">
                                {el.subGroupings.length > 0 && el.subGroupings.map((c) => (
                                    <>
                                        <tr className="font-bold h-[5px]">
                                            <th colSpan="3" className="md:w-[70%] truncate"></th>
                                        </tr><tr className="font-bold">
                                            <th colSpan="3" className="md:w-[70%] truncate">{c.description} <span className="text-gray-500">{c.subheading}</span></th>
                                        </tr>

                                        {c.investments.length > 0 && c.investments.map((inv, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td className="truncate">{inv.investmentName}</td>
                                                    <td className="text-right tabular-nums"><Moment date={inv.initialInvestmentDate} format="DD/MM/YYYY" /></td>
                                                    <td className="text-right tabular-nums"><Money prefix={'£'} value={inv.invested} showNil={false}  /></td>
                                                    <td className="text-right tabular-nums"><Money prefix={'£'} value={inv.value}  showNil={false} /></td>
                                                    <td className="text-right tabular-nums"><Percentage value={inv.gain} decimalPlaces={2} showNil={false} /></td>
                                                </tr>

                                            </>
                                        ))}

                                        {c.totalGainPercent != null &&
                                            <tr>
                                                <td colspan="4" className="truncate"></td>
                                                <td className="text-right font-bold tabular-nums"><Percentage value={c.totalGainPercent} decimalPlaces={2} showNil={false} /></td>
                                            </tr>
                                        }

                                        {c.totalGainPercent != null &&
                                            <tr className="h-[26px]">
                                                <td colspan="4" className="truncate"></td>
                                                <td className="text-right font-bold tabular-nums"></td>
                                            </tr>
                                        }               
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </SummaryWrapper>
                </div>
            ))}
        </>
    )
}

export default PortfolioPerformanceSummaryByProduct;