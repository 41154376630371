import React, { useRef, useState, useEffect } from "react";

const FundCommentaryHtmlSection = ({ html, header, headerClass }) => {

    const srcDoc = "<div dangerouslySetInnerHTML={{__html: html }}></div>";

    const ref = useRef();
    const [height, setHeight] = useState('0px');
  
    const onLoad = () => {
        if(ref && ref.current && ref.current.contentWindow){
            setHeight(ref.current.contentWindow.document.body.scrollHeight+35 + 'px');
        }
    };

    useEffect(() => {
      onLoad();
    }, []);
    if(header != null && header != ""){
        return (
            <div className="my-4">
                <div className={`w-full mb-2 p-1 text-center text-white text-bold ${headerClass}`}>
                    <h2 className="text-md">{header}</h2>
                </div>
                <iframe
                    ref={ref}
                    onLoad={onLoad}
                    className='w-full'
                    id='iframe'
                    srcDoc={'<style> body {font-family: nter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; color: #3f3f46}</style>' + html}
                    height={height}
                ></iframe>
            </div>
        )
    }

    return (
        <iframe
        ref={ref}
        onLoad={onLoad}
        className='w-full'
        id='iframe'
        srcDoc={'<style> body {font-family: nter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; color: #3f3f46}</style>' + html}
        height={height}
      ></iframe>
    );
};

export default FundCommentaryHtmlSection;
