import React from "react";

export const ProfileSkeleton = ({ className, height = "h-10", width = "w-10", ...rest }) => {
    return <div 
    className={`animate-pulse bg-slate-200 text-left border border-slate-200 rounded-full ${height} ${width} ${className ? className : ''}`}
        {...rest}
    />
}

const Skeleton = ({ className, height = "h-7", width = "w-full", ...rest }) => {
    return <div 
        className={`animate-pulse bg-slate-200 text-left border border-slate-200 rounded ${height} ${width} ${className ? className : ''}`}
        {...rest}
    />
};

export default Skeleton;