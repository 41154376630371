import React from "react";
import SummaryWrapper from "./SummaryWrapper";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import LoadingSkeleton from "./LoadingSkeleton";
import Money from "../../../components/Money";
import usePerformanceHistoricalReturn from "../hooks/usePerformanceHistoricalReturn";
import Percentage from "../../../components/Percentage";

const HistoricalTotalReturnSummary = () => {

    const { data, error, isFetching, isLoading } = usePerformanceHistoricalReturn();

    if(isLoading || isFetching){
        return(
            <SummaryWrapper>
                <LoadingSkeleton />
            </SummaryWrapper>
        );
    }

    if(error){
        return (
            <SummaryWrapper>
                <div className="flex flex-col w-full text-center">
                    <FetchErrorMessage />
                </div>
            </SummaryWrapper>
        );
    }     

    return (<SummaryWrapper type="static" title={"All Funds and Investments"} value={data.totalFundValues}>
                <table className="w-full table-fixed p-6 text-zinc-600 text-xs lg:text-base">
                    <thead>
                        <tr className="text-blue-900">
                            <th className="md:w-[50%] text-xs md:text-sm lg:text-base">Fund</th>
                            <th className="text-right text-xs md:text-sm lg:text-base hidden lg:table-cell">Value</th>
                            <th className="text-right text-xs md:text-sm lg:text-base">1M</th>
                            <th className="text-right text-xs md:text-sm lg:text-base hidden xl:table-cell">3M</th>
                            <th className="text-right text-xs md:text-sm lg:text-base">6M</th>
                            <th className="text-right text-xs md:text-sm lg:text-base">1Y</th>
                            <th className="text-right text-xs md:text-sm lg:text-base">3Y(PA)</th>
                            <th className="text-right text-xs md:text-sm lg:text-base hidden lg:table-cell">5Y(PA)</th>
                        </tr>
                    </thead>
                    <tbody className="overflow-x-auto">
                        {data.investments.length > 0 && data.investments.map((inv, index) => (
                            <tr key={index}>
                                <td className="truncate">{inv.investmentName}</td>
                                <td className="text-right tabular-nums hidden lg:table-cell"><Money prefix={'£'} value={inv.value} /></td>
                                <td className="text-right tabular-nums"><Percentage value={inv.totalReturn1m} decimalPlaces={2}/></td>
                                <td className="text-right tabular-nums hidden xl:table-cell"><Percentage value={inv.totalReturn3m} decimalPlaces={2} /></td>
                                <td className="text-right tabular-nums"><Percentage value={inv.totalReturn6m} decimalPlaces={2} /></td>
                                <td className="text-right tabular-nums"><Percentage value={inv.totalReturn12m} decimalPlaces={2} /></td>
                                <td className="text-right tabular-nums"><Percentage value={inv.averageTotalReturn3Year} decimalPlaces={2} /></td>
                                <td className="text-right tabular-nums hidden lg:table-cell"><Percentage value={inv.averageTotalReturn5Year} decimalPlaces={2} /></td>
                            </tr>
                        ))}
                        {data == null || data.investments.length <= 0 && 
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        }     
                    </tbody>
                </table>
            </SummaryWrapper>
    )
}

export default HistoricalTotalReturnSummary;