import React from "react";
import useDocumentYearsList from "../features/documents/hooks/useDocumentYearsList";
import Documents, { DocumentsLoader } from "../features/documents";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import { useParams } from "react-router-dom";

const DocumentsPage = () => {
    const { slug } = useParams();
    const { data, isError, isFetching, isLoading } = useDocumentYearsList(slug);
    const { dates, documentType } = data || { documentType: "Failed to Load." };

    return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader isLoading={isFetching || isLoading}>
                    {documentType}
                </WebsitePageHeader>
                {(isFetching || isLoading) && <DocumentsLoader />}
                {!isFetching && !isLoading && (isError || !data) && (
                    <p className="text-sm text-red-500 md:text-center">
                        Well this is embarassing! Seems there was a problem
                        getting the data from our servers. Please try again and
                        if the problem persists, contact your adviser.
                    </p>
                )}
                {!isFetching &&
                    !isLoading &&
                    !isError &&
                    dates &&
                    Array.isArray(dates) &&
                    dates.length === 0 && (
                        <p className="text-sm text-slate-700 md:text-center">
                            We're sorry! There seems to be no {documentType}s
                            available for your account.
                        </p>
                    )}
                {!isFetching &&
                    !isLoading &&
                    !isError &&
                    dates &&
                    Array.isArray(dates) &&
                    dates.length > 0 && <Documents dates={dates} slug={slug} />}
            </div>
        </React.Fragment>
    );
};

export default DocumentsPage;
