import React, { useState } from "react";
import Money from "../../../components/Money";
import Button from "../../../components/Button";
import FundCommentaryInfoModal from "./FundCommentaryInfoModal";


const FundCommentaryCard = ({ data, isLoading = false, error = false }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <>
            {isModalOpen && <FundCommentaryInfoModal
                title={data.fundName}
                assetId={data.assetId}
                assetGroupId={data.assetGroupId}
                fundName={data.fundName}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />}
            <div className="flex items-center border bg-white text-purple-700 rounded border-slate-300 my-4 p-4">
                <table className="w-full table-fixed">
                    <thead>
                        <tr className="">
                            <th className="md:w-[70%] text-left">
                                {data.fundName}
                            </th>
                            <th className="text-right">
                                <Money value={data.amount} />
                            </th>
                            <th className="text-right">{data.opinion}</th>
                            <th className="text-right">
                                <Button onClick={() => setIsModalOpen(true)}>
                                    Info
                                </Button>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
        </>
    );
};

export default FundCommentaryCard;
