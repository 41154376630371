import { useGetValuationSummaryQuery } from "../../../redux";

const usePortfolioSummary = () => {
    const data = useGetValuationSummaryQuery();

    return {
        ...data
    };
};

export default usePortfolioSummary;