import React from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const NotFoundPage = ({ homeLink = "/" }) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Page not found: WealthPro</title>
            </Helmet>
            <div className="flex flex-1 md:justify-center md:items-center">
                <div className="flex flex-col md:flex-row md:max-w-screen-lg md:items-center">
                    <div className="flex justify-center items-center md:p-3">
                        <span className="font-bold text-[12rem] text-slate-400">404</span>
                    </div>
                    <div className="px-3">
                        <h1 className="font-bold text-3xl text-blue-500">Whoops!</h1>
                        <p className="my-4 text-sm text-slate-600">Sorry, but it looks like the page you were looking for cannot be found.</p>
                        <div className="flex flex-col md:flex-row gap-2">
                            <NavLink to={homeLink} className="bg-blue-500 hover:bg-blue-700 text-white no-underline text-xs font-bold py-2 px-4 rounded" href={homeLink}>Go back Home</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>   
    )
};

export default NotFoundPage;