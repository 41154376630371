import React from "react";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import PortfolioSummary from "../features/portfoliosummary";

const PortfolioSummaryPage = () => {
    return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader>Summary</WebsitePageHeader>
                <PortfolioSummary />
            </div>
        </React.Fragment>
    );
};

export default PortfolioSummaryPage;
