import React from "react";
import Moment from "react-moment";
import LoadingSkeleton from "./LoadingSkeleton";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import PortfolioValuationInvestmentSummaryWrapper from "./PortfolioValuationInvestmentsSummaryWrapper";
import usePortfolioInvestmentsByUnitsAndShares from "../hooks/usePortfolioInvestmentsByUnitsAndShares";
import { NumericFormat } from "react-number-format";

const PortfolioValuationInvestmentSummaryByUnitsAndSharesTableHeader = () => (
    <thead>
        <tr className="text-blue-900">
            <th className="w-2/5 print:w-1/3 md:print:w-1/3"></th>
            <th className="text-left hidden print:hidden md:table-cell md:print:hidden">Reference</th>
            <th className="text-right">Units</th>
            <th className="text-right hidden xl:table-cell print:table-cell">Price Date</th>
            <th className="text-right hidden xl:table-cell print:table-cell">Price</th>
            <th className="text-right">Value</th>
        </tr>
    </thead>
);

const PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingHeaderRow = ({ data = null, usePrintSubheading = false }) => (
    <p className="m-0 text-zinc-600">{data?.description} <span className="text-gray-500">{usePrintSubheading ? `(${data?.subheadingPrint})` : data?.subheading}</span></p>
);

const PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingTotalsRow = ({ data = null, prefix = "", decimalScale = 0, hasNoValue = () => false }) => {
    return hasNoValue(data)
        ? (<span>-</span>)
        : (<NumericFormat prefix={prefix} value={data} thousandSeparator="," decimalScale={decimalScale} displayType="text" />)
}

const PortfolioValuationInvestmentSummaryByUnitsAndShares = () => {
    const { data, error, isFetching, isLoading } = usePortfolioInvestmentsByUnitsAndShares();

    /**
     * Basically checks if the value is a number and contains
     * !inv?.units || typeof(inv.units) !== 'number' || inv.units === 0
     * @param {*} v 
     * @returns 
     */
    const hasNoValue = (v) => !v || typeof(v) !== 'number' || v === 0; 

    if (isLoading || isFetching) {
        return(
            <PortfolioValuationInvestmentSummaryWrapper>
                <LoadingSkeleton />
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if (!error && data == null || data.length <= 0) {
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full">
                    <table className="w-full border-separate border-spacing-x-2 table-fixed text-zinc-600 text-xs lg:text-base">
                        <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableHeader />
                        <tbody className="overflow-x-auto">
                            <tr>
                                <td>There are no investments to show.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }

    if (error){
        return (
            <PortfolioValuationInvestmentSummaryWrapper>
                <div className="flex flex-col w-full text-center">
                    <FetchErrorMessage />
                </div>
            </PortfolioValuationInvestmentSummaryWrapper>
        );
    }     

    return (
        <>
            {data != null && data.length > 0 && data.map((el, i) => (
                <div key={i} className="mb-3">
                    <PortfolioValuationInvestmentSummaryWrapper type="accordion" title={el.description} value={el.totalFundValues} allocation={el.totalAllocation}>
                        <table className="w-full border-separate border-spacing-x-2 table-fixed text-zinc-600 text-xs lg:text-base print:text-xxs md:print:text-xxs lg:print:text-xxs">
                            <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableHeader />
                            <tbody className="overflow-x-auto">
                                {el.subGroupings.length > 0 && el.subGroupings.map((c) => (
                                    <>
                                        <tr>
                                            <th colspan="3" className="font-bold truncate md:hidden md:print:hidden print:hidden">
                                                <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingHeaderRow data={c} usePrintSubheading={true} />
                                            </th>
                                            <th colspan="4" className="font-bold truncate hidden print:hidden md:table-cell md:print:hidden xl:hidden">
                                                <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingHeaderRow data={c} />
                                            </th>
                                            <th colspan="5" className="font-bold truncate hidden print:table-cell">
                                                <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingHeaderRow data={c} usePrintSubheading={true} />
                                            </th>
                                            <th colspan="6" className="font-bold truncate hidden print:hidden xl:table-cell xl:print:hidden">
                                                <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingHeaderRow data={c} />
                                            </th>
                                        </tr>
                                        {c.investments.length > 0 && c.investments.map((inv, index) => (
                                            <tr key={index}>
                                                <td className="truncate">
                                                    {inv.investmentName}
                                                </td>
                                                <td className="truncate text-left tabular-nums hidden print:hidden md:table-cell md:print:hidden">
                                                    {inv.contractReference}
                                                </td>
                                                <td className="truncate text-right tabular-nums">
                                                    {(hasNoValue(inv?.units)) 
                                                        ? (<span>-</span>)
                                                        : (<NumericFormat value={inv.units} thousandSeparator="," fixedDecimalScale={4} decimalScale={4} displayType="text" />)
                                                    }
                                                </td>
                                                <td className="truncate text-right tabular-nums hidden xl:table-cell print:table-cell">
                                                    {(inv?.priceDate == null)
                                                        ? (<span>-</span>)
                                                        : (<Moment date={inv.priceDate} format="DD/MM/YY" />)
                                                    }
                                                </td>
                                                <td className="truncate text-right tabular-nums hidden xl:table-cell print:table-cell">
                                                    {(hasNoValue(inv?.price))
                                                        ? (<span>-</span>)
                                                        : (<NumericFormat value={inv.price} thousandSeparator="," fixedDecimalScale={4} decimalScale={4} displayType="text" />)
                                                    }
                                                </td>
                                                <td className="truncate text-right tabular-nums">
                                                    {(hasNoValue(inv?.value))
                                                        ? (<span>-</span>)
                                                        : (<NumericFormat prefix="£" value={inv.value} thousandSeparator="," decimalScale={0} displayType="text" />)
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <th colspan="3" className="text-right tabular-nums md:hidden md:print:hidden print:hidden">
                                                <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingTotalsRow data={c?.totalFundValues} prefix="£" hasNoValue={hasNoValue} />
                                            </th>
                                            <th colspan="4" className="text-right tabular-nums hidden print:hidden md:table-cell md:print:hidden xl:hidden">
                                                <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingTotalsRow data={c?.totalFundValues} prefix="£" hasNoValue={hasNoValue} />
                                            </th>
                                            <th colspan="5" className="text-right tabular-nums hidden print:table-cell">
                                                <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingTotalsRow data={c?.totalFundValues} prefix="£" hasNoValue={hasNoValue} />
                                            </th>
                                            <th colspan="6" className="text-right tabular-nums hidden print:hidden xl:table-cell xl:print:hidden">
                                                <PortfolioValuationInvestmentSummaryByUnitsAndSharesTableSubGroupingTotalsRow data={c?.totalFundValues} prefix="£" hasNoValue={hasNoValue} />
                                            </th>
                                        </tr>
                                    </>
                                ))}           
                            </tbody>
                        </table>
                    </PortfolioValuationInvestmentSummaryWrapper>
                </div>
            ))}
        </>
    )
}

export default PortfolioValuationInvestmentSummaryByUnitsAndShares;