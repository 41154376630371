import React from "react";
import Money from "../../../components/Money";
import usePortfolioWealth from "../hooks/usePortfolioWealth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const TotalWealthDisplay = () => {
    
    const { data, error, isFetching, isLoading } = usePortfolioWealth();
    
    return <table className="flex-1">
                <thead>
                    <tr className="text-lg lg:text-2xl w-[100%] font-bold text-gray-600">
                        {isLoading && !error && <th className="animate-pulse">Total Wealth {" "} : £...</th>}
                        {error &&  <th><FontAwesomeIcon  icon="fa-triangle-exclamation" /></th>}
                        {!isLoading && !isFetching && !error && data!= null && <th>Total Wealth {" "} : <Money prefix={'£'} value={data} showNil={false} /></th>}
                    </tr>
                </thead>
            </table>
};

export default TotalWealthDisplay;
