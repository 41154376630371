import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../../redux";
import { clear } from "../../redux/auth";

const useLogout = () => {
	const dispatch = useDispatch();
    const [_logout, logoutData] = useLogoutMutation();

	const logout = () => {
		_logout().unwrap().then(
			r => { },
			e => { /* TODO: we might want to display something to the user/client */ }
		).finally(_ => {
			dispatch(clear({ }));
		})
	};

	return logout;
};

export default useLogout;