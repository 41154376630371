import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../redux";
import { setAccessToken } from "../../redux/auth";

// TODO: handle login errors/invalid credentials & lockout accounts
// TODO: setup the redirect for the MFA

const useLogin = () => {
	const dispatch = useDispatch();
	const [_login, loginData] = useLoginMutation();

	const login = (username, password) => {
		return new Promise((resolve, reject) => {
			return _login({ username, password })
				.unwrap()
				.then(
					r => { 
						const { accessToken, user, sessionId, isMfaEnabled, mfaType } = r;
						if (isMfaEnabled === true) {
							window.location.href = `/verify?session=${sessionId}&mfa=${mfaType}`;
							return resolve(r);
						}
						
						dispatch(setAccessToken({ accessToken, user }));
						window.location.href = '/dashboard';
						return resolve(r);
					},
					e => { 
						return reject(e);
					}
				)
		});	
	};

	return login;
};

export default useLogin;