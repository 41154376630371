import React from "react";
import WebsiteHero from "../components/WebsiteHero";
import CompanyLogo from "../components/CompanyLogo";
import DisplayCard from "../components/DisplayCard";
import PasswordInputForm from "../components/forms/PasswordInputForm";
import LoadingSpinner from "../features/portfoliovaluation/components/LoadingSpinner";
import useResetPassword from "../components/hooks/useResetPassword";
import useVerifyPasswordResetCode from "../components/hooks/useVerifyResetPasswordResetCode";
import { Navigate, useSearchParams } from "react-router-dom";

const ResetPasswordPage = () => {
    const [searchParams] = useSearchParams();
    const [emailAddress, setEmailAddress] = React.useState(searchParams.get('emailAddress'));
    const [resetCode, setResetCode] = React.useState(searchParams.get('code'));
    const [resetErrors, setResetErrors] = React.useState([]);
    const [verifyErrors, setVerifyErrors] = React.useState([]);
    const { verify, isLoading } = useVerifyPasswordResetCode();
    const { reset, isLoading: isResettingPassword } = useResetPassword();

    const _reset = ({ password }) => {
        reset(emailAddress, resetCode, password).then(
            r => {
                window.location.href = `/signin?passwordReset=true`;
            },
            e => {
                setResetErrors(errors => {
                    return [...errors, { ...e.data }];
                });  
            }
        );
    };

    const _verify = ({ emailAddress, resetCode }) => {
        verify(emailAddress, resetCode).then(
            r => {
                setResetCode(_ => resetCode);
                setEmailAddress(_ => emailAddress);
            },
            e => {
                setVerifyErrors(errors => {
                    return [...errors, { ...e.data }];
                });  
            }
        );
    };

    React.useEffect(() => {
        if (!searchParams.has('emailAddress') || !searchParams.has('code')) {
            window.location.href = '/';
        }
        _verify({ emailAddress: searchParams.get('emailAddress'), resetCode: searchParams.get('code') });
    }, [searchParams]);

    return (
        <WebsiteHero>
             <DisplayCard className="bg-white text-black p-4 mx-4 lg:mx-0 max-w-xl">
                {isLoading && (
                    <div className="flex justify-center items-center text-center">
                        <LoadingSpinner label="Verifying password reset request..." />
                    </div>
                )}
                {!isLoading && verifyErrors.length > 0 && (
                    <Navigate to={'/'} replace />
                )}
                {!isLoading && verifyErrors.length === 0 && (
                    <React.Fragment>
                        <div className="flex flex-col justify-center items-center p-3">
                            <CompanyLogo />
                        </div>
                        <p className="flex-1 text-sm text-gray-500">
                            You reset request has been accepted. Please enter your new password credentials below to continue.
                        </p>
                        <PasswordInputForm 
                            displayWelcome={false} 
                            buttonText="Change password"
                            parentErrors={resetErrors}
                            isLoading={isResettingPassword}
                            onConfirm={_reset}
                        />
                    </React.Fragment>
                )}
             </DisplayCard>
        </WebsiteHero>
    );
};

export default ResetPasswordPage;