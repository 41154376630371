
import { useGetInvestmentsByShareClassSummaryQuery } from "../../../redux";

const usePortfolioInvestmentsByShareClass = () => {
    
    const data = useGetInvestmentsByShareClassSummaryQuery();

    return {
        ...data
    };
};

export default usePortfolioInvestmentsByShareClass;