import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import clsx from "clsx";

export const IconButton = ({ buttonClassName, iconClassName, icon, iconSize, onClick, ...rest }) => (
    <Button className={buttonClassName} onClick={onClick} {...rest}>
        <FontAwesomeIcon className={iconClassName} size={iconSize} icon={icon} />
    </Button>
);

export const LinkButton = ({ children, className, light, ...rest }) => (
    <a className={clsx(
        'text-xs text-center font-bold py-2 px-4 rounded',
        {
            "bg-blue-500 hover:bg-blue-700 text-white": !light,
            "bg-white hover:bg-gray-200 border": light,
        },
        className
    )} {...rest}>{children}</a>
);

const Button = ({ children, className, light, ...rest }) => {
    return (
        <button className={clsx(
            'text-xs font-bold py-2 px-4 rounded',
            {
                "bg-blue-500 hover:bg-blue-700 text-white": !light,
                "bg-white hover:bg-gray-200 border": light,
            },
            className
        )} {...rest}>
            {children}
        </button>
    )
};

export default Button;