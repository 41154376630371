import React from "react";
import clsx from "clsx";

// TODO: style some more...

const FormInput = React.forwardRef(({ label, pClassName, className, ...rest }, ref) => {
    const id = React.useId();
    const inputClassName = clsx('shadow appearance-none border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline', className);
    const parentClassName = clsx({
        'mb-2': !pClassName,
        [`${pClassName}`]: pClassName && pClassName.length > 0
    });
    
    return (
        <div className={parentClassName}>
            {label && (
                <label className="block text-gray-500 text-sm mb-2" htmlFor={id}>
                    {label}
                </label>
            )}
            <input ref={ref} id={id} className={inputClassName} {...rest} />
        </div>
    );
});

export default FormInput;