import React, { useMemo } from "react";

const Percentage = ({
    decimalPlaces = 0,
    prefix = "£",
    showNil = true,
    value = 0
}) => {
    const calculateDisplayValue = () => {

        if (showNil === true && value === 0){
            return <span>NIL</span>
        }
        
        if (value === null) {
            return <span>-</span>
        }

        return value.toFixed(decimalPlaces).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '%';
    };

    return <span>{calculateDisplayValue()}</span>
};

export default Percentage;