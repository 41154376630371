import React from "react";
import CompanyLogo from "./CompanyLogo";
import DisplayCard from "./DisplayCard";
import LoginForm from "./forms/LoginForm";
import LoginWelcomeDisplay from "./LoginWelcomeDisplay";
import { useSession } from "./SessionContext";

const LoginDisplayCard = () => {
    const { isAuthenticated, isLoading, user } = useSession();
    return (
        <DisplayCard className="bg-white text-black p-4 max-w-xl">
            <div className="flex flex-col justify-center items-center p-3">
                <CompanyLogo />
            </div>
            {isAuthenticated && !isLoading && (
                <LoginWelcomeDisplay user={user} />
            )}
            {(!isAuthenticated || isLoading) && (
                <LoginForm />
            )}
        </DisplayCard>
    );
};

export default LoginDisplayCard;