import React, { useMemo, useState } from "react";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import PortfolioChanges from "../features/portfoliochanges";

const PortfolioChangesPage = () => {
    return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader>Portfolio Changes</WebsitePageHeader>
                <PortfolioChanges />
            </div>
        </React.Fragment>
    );
};

export default PortfolioChangesPage;
