import React from "react";
import WebsiteHero from "../components/WebsiteHero";
import LoginVerificationForm from "../components/forms/LoginVerificationForm";
import LoginVerificationRegisterForm from "../components/forms/LoginVerificationRegisterForm";
import useVerifyMfaCode from "../components/hooks/useVerifyMfaCode";
import LoadingSpinner from "../features/portfoliovaluation/components/LoadingSpinner";
import { Navigate, useSearchParams } from "react-router-dom";

const UNREGISTERED_TOTP_ENUM = 3;

const SignInVerificationPage = ({ loginUrl = "/signin" }) => {
    const [searchParams] = useSearchParams();
    const [isVerified, setIsVerified] = React.useState(false);
    const sessionId = searchParams.get('session');
    const { verify, isLoading, data } = useVerifyMfaCode();

    React.useEffect(() => {
        if (!sessionId) {
            return;
        }

        verify(sessionId).then(
            _ => {
                setIsVerified(_ => true);
            },
            e => { window.location.href = loginUrl; }
        );
    }, [sessionId]);

    if (!sessionId) {
        return <Navigate to={loginUrl} replace={true} />
    }

    if (isLoading || !data.data) {
        return (
            <WebsiteHero>
                <div className="flex justify-center items-center text-center">
                    <LoadingSpinner label="Verifying..." />
                </div>
            </WebsiteHero>
        );
    }

    const { data: { mfaType, emailAddress, otpUri, otpSecureKey, image } } = data;
    return (
        <WebsiteHero>
            {isVerified && mfaType === UNREGISTERED_TOTP_ENUM && (
                <LoginVerificationRegisterForm 
                    sessionId={sessionId} 
                    mfaType={mfaType}
                    emailAddress={emailAddress}
                    otpUri={otpUri}
                    otpSecureKey={otpSecureKey}
                    otpImage={image}
                />
            )}
            {isVerified && mfaType !== UNREGISTERED_TOTP_ENUM && (
                <LoginVerificationForm 
                    sessionId={sessionId} 
                    mfaType={mfaType} 
                />
            )}
        </WebsiteHero>
    );
};

export default SignInVerificationPage;