import React from "react";
import Moment from "react-moment";
import Button from "../components/Button";
import WebsitePageHeader from "../components/WebsitePageHeadings";
import SignatureCanvas from "react-signature-canvas";
import { GreenBadge, RedBadge } from "../components/Badge";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DocumentsSignPage = () => {
    let signatureCanvas = null;
    const [searchParams] = useSearchParams();

    const onClearSignature = () => {
        if (!signatureCanvas) {
            return;
        }

        signatureCanvas.clear();
    };

    return (
        <React.Fragment>
            <div className="p-4">
                <WebsitePageHeader>Sign your Document</WebsitePageHeader>
                <div className="w-full bg-white shadow-inner border border-slate-300">
                    <div className="border-b border-slate-300 py-4">
                        <div className="flex mb-4">
                            <div className="mx-6">
                                <FontAwesomeIcon icon="fa-file" size="2xl" />
                            </div>
                            <p className="flex-1 leading-none">
                                <span>Your Document Name</span>
                                <br />
                                <span className="text-xs text-slate-500">
                                    Uploaded <Moment format="MMMM Do, YYYY" />
                                </span>
                            </p>
                            <div className="me-6">
                                <Button>Download</Button>
                            </div>
                        </div>
                        <div className="px-6">
                            <span className="">Required Signatures:</span>
                            <div className="flex flex-col m-4">
                                <div className="flex items-center mb-2">
                                    <p className="me-2">Mr Joe Sample</p>
                                    <RedBadge>Unsigned</RedBadge>
                                </div>
                                <div className="flex items-center">
                                    <p className="me-2">Mrs Jane Sample</p>
                                    <GreenBadge>
                                        Signed on July 24th, 2023 11:00am
                                    </GreenBadge>
                                </div>
                            </div>
                        </div>
                        <p className="px-6 text-sm italic text-slate-500">
                            Please note that for us to continue any business
                            relating to the attached documents. All signatures
                            must be present.
                        </p>
                    </div>
                    <div className="p-6">
                        <p className="text-sm mb-3">
                            By clicking agree &amp; signing below, you agree for
                            us to continue with the advice being provided. You
                            also agree to our{" "}
                            <span className="text-blue-400">
                                Terms of Business
                            </span>{" "}
                            and agree to the{" "}
                            <span className="text-blue-400">
                                Terms &amp; Services
                            </span>{" "}
                            provided.
                        </p>
                        <div className="relative w-fit mb-3">
                            <SignatureCanvas
                                ref={(ref) => (signatureCanvas = ref)}
                                penColor="black"
                                canvasProps={{
                                    width: 500,
                                    height: 200,
                                    className:
                                        "sigCanvas border border-slate-300",
                                }}
                            />
                            <div className="absolute top-0 right-0 py-3 px-4">
                                <FontAwesomeIcon
                                    icon="fa-x"
                                    className="cursor-pointer z-20"
                                    onClick={onClearSignature}
                                />
                            </div>
                        </div>
                        <div className="flex items-center md:justify-end">
                            <Button>Agree &amp; Continue</Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DocumentsSignPage;
