import React from "react";
import LoadingSpinner from "../portfoliovaluation/components/LoadingSpinner";
import usePortfolioChanges from "./hooks/usePortfolioChanges";

const PortfolioChanges = () => {
    const { data, isLoading } = usePortfolioChanges();
    const rowClassName = `p-3 border border-gray-200 bg-white`;
  
    return (
        <React.Fragment>
            <p className="p-2">
                Our administration records indicate that you currently have
                transactions in progress.
            </p>
            <p className="p-2">
                It is possible that the status of fund holdings and cash
                balances could be inaccurate whilst transactions are in progress
                therefore your investment values might be overstated. If you
                have any queries then please contact your adviser who will be
                pleased to assist.
            </p>
            <p className="p-2">
                <b>
                    Please do not rely on portfolio values shown until all
                    transactions have been traded and verified by our
                    administration team.
                </b>
            </p>

            <table className="w-full table-auto p-6 mt-4 border-collapse border border-slate-700">
                <thead>
                    <tr className="bg-blue-900 text-white p-3 text-left ">
                        <th className="p-3" scope="col">Owner</th>
                        <th className="p-3" scope="col">Product</th>
                        <th className="p-3" scope="col">Provider</th>
                        <th className="p-3" scope="col">Operation</th>
                        <th className="p-3" scope="col">Fund Name</th>
                        <th className="p-3" scope="col">Traded</th>
                        <th className="p-3" scope="col">Status</th>
                    </tr>
                </thead>
                <tbody className="overflow-x-auto">
                    {isLoading && (
                        <tr className="p-2">
                            <td colSpan={7} className="align-middle">
                                <LoadingSpinner label="Checking for changes in your Portfolio..." />
                            </td>
                        </tr>
                    )}
                    {!isLoading && data && data.length === 0 && (
                        <tr className="p-2">
                            <td colSpan={7} className="text-slate-500">
                                <p className="mb-0">There are no changes active on your Portfolio.</p>
                            </td>
                        </tr>
                    )}
                    {!isLoading && data && data.map((row, i) => (
                        <tr key={i} className="p-2">
                            <td className={rowClassName}>{row.designationName}</td>
                            <td className={rowClassName}>{row.productName}</td>
                            <td className={`p-3 border border-gray-200 bg-white ${row.providerName == null ? 'text-center' : ''}`}>{(row.providerName) ? row.providerName : "-"}</td>
                            <td className={rowClassName}>{row.transactionName}</td>
                            <td className={rowClassName}>{row.assetName}</td>
                            <td className={rowClassName}>{row.traded}</td>
                            <td className={rowClassName}>{row.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default PortfolioChanges;