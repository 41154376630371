import wealthProApi from "../api";

const documentEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        fetchDocumentsForYear: builder.query({
            query: ({ slug, year }) => `api/documents/${slug}/${year}`
        }),
        fetchDocumentYears: builder.query({
            query: ({ slug }) => `api/documents/${slug}`
        }),
        fetchDocument: builder.query({
            query: ({documentId}) => `api/documents/${documentId}`
        })
    })
});

export const {
    useFetchDocumentsForYearQuery,
    useFetchDocumentYearsQuery,
    useLazyFetchDocumentQuery,
} = documentEndpoints;