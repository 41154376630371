import _ from "lodash";
import clsx from "clsx";
import React from "react";
import Button from "../Button";
import FormInput from "./FormInput";
import LoadingSpinner from "../../features/portfoliovaluation/components/LoadingSpinner";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const PasswordValidationListItem = ({ validation = {}, validationProperty = "", children }) => {
    return <li className={clsx({
        'text-red-500': !validation[validationProperty],
        'text-green-500': validation[validationProperty] === true 
    })}>
        <FontAwesomeIcon icon={!validation[validationProperty] ? faTimes : faCheck} />{' - '}{children}
    </li>
}


const PasswordInputForm = ({
    parentErrors = [], 
    displayName = '', 
    isLoading = false, 
    onConfirm = () => { },
    buttonText = "Register account",
    displayWelcome = true,
    verifyResultFailureMessage = "There was a problem verifying your welcome code."
}) => {
    const [validation, setValidation] = React.useState({ inRange: false, hasUpper: false, hasLower: false, hasNumber: false, passwordsMatch: false });
    const isPasswordValidated = _.values(validation).every(e => e === true);
    const { control, formState: { errors }, handleSubmit, watch } = useForm({ defaultValues: { password: '', confirmPassword: '' } });
    
    const errorMessage = React.useMemo(() => {
        if (errors?.root?.verifyResult) {
            return errors?.root?.verifyResult ?? verifyResultFailureMessage;
        }
        if (parentErrors.length > 0) {
            return parentErrors[0].message;
        }
        if (errors?.emailAddress?.message) {
            return errors.emailAddress.message;
        }
        if (errors?.welcomeCode?.message) {
            return errors.welcomeCode.message;
        }
        return null;
    }, [errors, parentErrors]);

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const { password, confirmPassword } = value;
            let inRange = password.length >= 8 && password.length <= 50;
            let hasUpper = /[A-Z]/.test(password);
            let hasLower = /[a-z]/.test(password);
            let hasNumber = /[0-9]/.test(password);
            let passwordsMatch = password.length > 0 && confirmPassword.length > 0 && password == confirmPassword;
            setValidation(_ => {
                return {
                    inRange,
                    hasUpper,
                    hasLower,
                    hasNumber,
                    passwordsMatch
                };
            });
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <React.Fragment>
            {displayWelcome === true && (
                <div className="text-sm text-gray-500 mx-2">
                    <h1>Welcome{displayName && displayName.length > 0 ? `, ${displayName}.` : '.'}</h1>
                    <br />
                    <p>Your account is setup and ready to go. All you need to do is create a secure password for your account.</p>
                </div>
            )}
            <form className="flex flex-col gap-3 w-full my-4 border p-5" onSubmit={handleSubmit(onConfirm)}>
                {errorMessage && (
                    <div className="">
                        <p className="m-0 text-sm text-red-500">{errorMessage ?? "There was a problem with your request."}</p>
                    </div>
                )}
                <Controller 
                    name="password"
                    control={control}
                    rules={({ required: 'Password is required' })}
                    render={({ field: { ref, ...rest } }) => (
                        <FormInput 
                            pClassName="mb-0" 
                            label="Password" 
                            type="password"
                            maxLength={64}
                            disabled={isLoading}
                            autoComplete="new-password"
                            {...rest} 
                        />
                    )}
                />
                <Controller 
                    name="confirmPassword"
                    control={control}
                    rules={({ required: 'Password is required' })}
                    render={({ field: { ref, ...rest } }) => (
                        <FormInput 
                            pClassName="mb-0" 
                            label="Confirm Password" 
                            type="password"
                            maxLength={64}
                            disabled={isLoading}
                            autoComplete="new-password"
                            {...rest} 
                        />
                    )}
                />
                <ul className="px-2 py-1 text-sm">
                    <PasswordValidationListItem validation={validation} validationProperty="inRange">Password must be between 8 and 50 characters</PasswordValidationListItem>
                    <PasswordValidationListItem validation={validation} validationProperty="hasUpper">Must contain at least 1 upper case letter</PasswordValidationListItem>
                    <PasswordValidationListItem validation={validation} validationProperty="hasLower">Must contain at least 1 lower case letter</PasswordValidationListItem>
                    <PasswordValidationListItem validation={validation} validationProperty="hasNumber">Must contain at least 1 number</PasswordValidationListItem>
                    <PasswordValidationListItem validation={validation} validationProperty="passwordsMatch">Passwords must match</PasswordValidationListItem>
                </ul>
                <Button className="w-full text-center" type="submit" disabled={isLoading || !isPasswordValidated}>
                    {!isLoading ? (<>{buttonText}</>) : (<LoadingSpinner width={3} height={3} />)}
                </Button>
            </form>
            
        </React.Fragment>
    );
};

export default PasswordInputForm;