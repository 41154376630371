import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DocumentIcon = ({ className, icon = null, iconColor = null, fileType = null, ...rest }) => {
    const calculateIcon = () => {
        if (icon) {
            return icon;
        }

        switch(fileType) {
            case 0:
                return "fa-file-pdf";
            case 1:
                return "fa-file-word";
            default:
                return "fa-file";
        }
    };

    const calculateIconColor = () => {
        if (iconColor) {
            return iconColor;
        }

        switch(fileType) {
            case 0:
                return "text-rose-500";
            case 1:
                return "text-sky-500";
            default:
                return "text-blue-900";
        }
    };

    return <FontAwesomeIcon 
        icon={calculateIcon()} size="xl" 
        className={`${calculateIconColor()} ${className ? className : ''}`} 
        {...rest} 
    />
};

export default DocumentIcon;