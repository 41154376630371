import React from "react";
import FinancialExpressLogo from "./FinancialExpressLogo";
import WealthProLogo from "./WealthProLogo";

// TODO: create modals for all the accessibility, cookie policy, etc

const WebsiteFooter = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="flex justify-between p-4 flex-nowrap bg-white">
            <div>
                <FinancialExpressLogo className="w-12" />
            </div>
            <div className="flex flex-col justify-center items-center">
                {/* <ul className="flex text-xs mb-1">
                    <li className="mx-2">Accessibillity</li>
                    <li className="mx-2">Cookie Policy</li>
                    <li className="mx-2">Legal</li>
                    <li className="mx-2">Security &amp; Privacy</li>
                    <li className="mx-2">Terms &amp; Conditions</li>
                </ul> */}
                <p className="text-xs text-slate-500">
                    © {year} Save &amp; Invest Group Ltd. All right reserved.
                </p>
            </div>
            <div>
                <WealthProLogo className="w-16" />
            </div>
        </footer>
    );
};

export default WebsiteFooter;