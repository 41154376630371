import React from 'react';
import toast from 'react-hot-toast';
import { LoadingPage } from '../pages';
import { useSession } from './SessionContext';
import { 
    useLazyGetPortfolioChangesQuery,
    useLazyGetPortfolioChangesCountQuery 
} from "../redux";


const PortfolioChangesContext = React.createContext();

const usePortfolioChanges = () => React.useContext(PortfolioChangesContext);

export const PortfolioChangesProvider = ({ children }) => {
    const { isAuthenticated } = useSession();
    const [hasShownChanges, setHasShownChanges] = React.useState(sessionStorage.getItem('wp-changes-shown') === "true" ? true : false);
    const [fetchPortfolioChanges, { isLoading: isPortfolioChangesLoading, ...portfolioChangesData }] = useLazyGetPortfolioChangesQuery();
    const [fetchPortfolioChangesCount, { isLoading: isPortfolioChangesCountLoading, isUninitialized, ...portfolioChangesCountData }] = useLazyGetPortfolioChangesCountQuery();
    const isLoading = isPortfolioChangesLoading || isPortfolioChangesCountLoading;
    const { data: changesCount } = portfolioChangesCountData;
    
    const _loadPortfolioChanges = () => fetchPortfolioChanges({ });

    const _loadPortfolioChangesCount = () => fetchPortfolioChangesCount({ });

    React.useEffect(() => {
        if (hasShownChanges === true || isAuthenticated === false) {
            return;
        }
        _loadPortfolioChangesCount();
    }, [hasShownChanges, isAuthenticated]);
    
    return (
        <PortfolioChangesContext.Provider value={{
            changesCount,
            data: {
                portfolioChanges: { isLoading: isPortfolioChangesLoading, ...portfolioChangesData },
                portfolioChangesCount: { isPortfolioChangesCountLoading, isUninitialized, ...portfolioChangesCountData }
            },
            getChangesCount: _loadPortfolioChangesCount,
            hasShownChanges,
            isLoading,
            isUninitialized,
            portfolioChanges: portfolioChangesData?.data,
            reload: _loadPortfolioChanges,
            setHasShownChanges
        }}>
            {(isLoading === true || isUninitialized === true) ? (<LoadingPage />) : (children)}
        </PortfolioChangesContext.Provider>
    );

}

export default usePortfolioChanges;