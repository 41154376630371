/**
 * Session Context is in charge of keeping the session in memory, providing logins and the access token when making requests.
 * Will also refresh the token in intervals.
 */

import React from "react";
import store from "../redux/store";
import withProviders from "./hooks/withProviders";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { useRefreshMutation } from "../redux";
import { setAccessToken, setLoadingAuth, selectAccessToken, selectIsLoading, selectIsUninitialized, selectUser  } from "../redux/auth";

const SessionContext = React.createContext();

export const useSession = () => React.useContext(SessionContext);

const SessionProvider = ({ children, refreshTimeout = 250000 }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isLoading = useSelector(selectIsLoading);
    const isUninitialized = useSelector(selectIsUninitialized);
    const accessToken = useSelector(selectAccessToken);
    const [refreshToken, refreshData] = useRefreshMutation();

    /**
     * 
     */
    const _refreshToken = () => {
        dispatch(setLoadingAuth({ isLoading: true }));
        refreshToken().unwrap().then(
            r => {
                const { accessToken, user } = r;
                dispatch(setAccessToken({ accessToken, user }));
            },
            e => { /* TODO: we might want to display something to the user/client */ }
        ).finally(_ => {
            dispatch(setLoadingAuth({ isLoading: false }));
        });
    };

    React.useEffect(() => {
        if (accessToken) {
            return;
        }
        return _refreshToken();
    }, []);

    React.useEffect(() => {
        let timer;
        if (accessToken) {
            timer = setTimeout(_refreshToken, refreshTimeout)
        }
        return () => clearTimeout(timer);
    }, [ accessToken ]);

    return (
        <SessionContext.Provider value={{ 
            accessToken,
            data: { refresh: refreshData },
            isAuthenticated: accessToken !== null,
            isLoading,
            isUninitialized,
            user
        }}>
            <HelmetProvider>
                {children}
            </HelmetProvider>
        </SessionContext.Provider>
    );
};

export default withProviders([ReduxProvider, { store }])(SessionProvider);