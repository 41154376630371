import React, { useMemo, useState } from "react";
import PortfolioValuationViewSelector from "./components/PortfolioValuationViewSelector";
import PortfolioValuationInvestmentSummary from "./components/PortfolioValuationInvestmentSummary";
import PortfolioValuationInvestmentSummaryByShareClass from "./components/PortfolioValuationInvestmentSummaryByShareClass";
import PortfolioValuationInvestmentSummaryByVolatility from "./components/PortfolioValuationInvestmentSummaryByVolatility";
import PortfolioValuationInvestmentSummaryByOwners from "./components/PortfolioValuationInvestmentsSummaryByOwners";
import PortfolioValuationInvestmentSummaryByProduct from "./components/PortfolioValuationInvestmentsSummaryByProduct";
import PortfolioValuationInvestmentSummaryByUnitsAndShares from "./components/PortfolioValuationInvestmentSummaryByUnitsAndShares";

const PortfolioValuationViews = [
    { id: 1, name: 'Investments Summary' },
    { id: 2, name: 'Investments Summary by Share Class' },
    { id: 3, name: 'Investments Summary by Volatility' },
    { id: 4, name: 'Investments Detailed by Owner' },
    { id: 5, name: 'Investments Detailed by Product' },
    { id: 6, name: 'Investments Detailed by Units & Shares' }
];

const PortfolioValuation = () => {
    const [currentTab, setCurrentTab] = useState(PortfolioValuationViews[0]);
    const featureDisplay = useMemo(() => {
        if (!currentTab || !currentTab.id) {
            return null;
        }

        switch(currentTab.id) {
            case 1:
                return <PortfolioValuationInvestmentSummary />;
            case 2:
                return (<PortfolioValuationInvestmentSummaryByShareClass />);
            case 3:
                return (<PortfolioValuationInvestmentSummaryByVolatility />);
            case 4:
                return (<PortfolioValuationInvestmentSummaryByOwners />);
            case 5:
                return (<PortfolioValuationInvestmentSummaryByProduct />);
            case 6:
                return (<PortfolioValuationInvestmentSummaryByUnitsAndShares />);
            default:
                return null;
        }
    }, [currentTab]);

    return (
        <React.Fragment>
            <PortfolioValuationViewSelector currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <div className="my-3">
                {featureDisplay}
            </div>
        </React.Fragment>
    )
};

export default PortfolioValuation;