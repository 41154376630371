import React from "react";

const WealthProText = ({ className, ...rest }) => {
    return (
        <p className={`text-xxs leading-none text-slate-500 ${className ? className : ''}`} {...rest}>
            Powered by WealthPro. © 2023 Save &amp; Invest Group Ltd. All right reserved.
        </p>
    );
};

export default WealthProText;