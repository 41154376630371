import wealthProApi from "./api";
import { authReducer } from "./auth";
import { configureStore } from "@reduxjs/toolkit";

export default configureStore({
    reducer: {
        auth: authReducer,
        [wealthProApi.reducerPath]: wealthProApi.reducer
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        wealthProApi.middleware
    ]
});