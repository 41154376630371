import wealthProApi from "../api";
import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";

const messageEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        fetchMasterAccountMessages: builder.query({
            query: ({ next, limit = 15 }) => `api/messages?limit=${limit}${next ? "&next=" + next : ""}`,
            providesTags: (result, err, arg) => ['message'],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}`
            },
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        fetchMasterAccountNotifications: builder.query({
            query: ({ next, limit = 15 }) => `api/notifications?limit=${limit}${next ? "&next=" + next : ""}`,
            providesTags: (result, err, arg) => ['notifications'],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}`
            },
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        createMessage: builder.mutation({
            query: (request) => ({
                url: `api/messages`,
                method: 'POST',
                body: request
            })
        }),
        deleteMessage: builder.mutation({
            query: ({ messageId }) => ({
                url: `api/messages/${messageId}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ messageId }, { dispatch, queryFulfilled }) {
                var result = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountMessages', {}, ({ results, ...rest }) => {
                    return {
                        results: results.filter(x => x.messageId !== messageId),
                        ...rest
                    };
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        patchMessage: builder.mutation({
            query: ({ messageId, operations }) => ({
                url: `api/messages/${messageId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ messageId, operations }, { dispatch, queryFulfilled }) {
                var result = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountMessages', {}, ({ results, ...rest }) => {
                    var item = results.find(x => x.messageId === messageId);
                    applyPatch(item, operations, true)
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        sendMessage: builder.mutation({
            query: ({ messageId }) => ({
                url: `api/messages/${messageId}`,
                method: 'POST'
            })
        }),
        updateMessageRecipients: builder.mutation({
            query: ({ messageId, recipients }) => ({
                url: `api/messages/${messageId}/recipients`,
                method: 'POST',
                body: {
                    recipientIdList: recipients.map(x => x.value)
                }
            }),
            async onQueryStarted({ messageId, recipients }, { dispatch, queryFulfilled }) {
                const mappedRecipients = recipients.map(x => ({ userId: x.value, recipientType: x.userType, displayName: x.label }));
                var result = dispatch(wealthProApi.util.updateQueryData('fetchMasterAccountMessages', {}, ({ results, ...rest }) => {
                    var message = results.find(el => el.messageId === messageId);
                    if (!message) return;
                    message.recipients = mappedRecipients;
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        fetchMessageContactsForClient: builder.query({
            query: () => `api/messages/contacts`
        })
    })
});

export const {
    useFetchMasterAccountMessagesQuery,
    useLazyFetchMasterAccountMessagesQuery,
    useLazyFetchMasterAccountNotificationsQuery,
    useCreateMessageMutation,
    useDeleteMessageMutation,
    usePatchMessageMutation,
    useUpdateMessageRecipientsMutation,
    useFetchMessageContactsForClientQuery,
    useSendMessageMutation
} = messageEndpoints;