import { useGetFundFactSheetQuery } from "../../../redux";

const useFundFactSheet = (assetGroupId, assetId) => {
    
    const data = useGetFundFactSheetQuery({assetGroupId: assetGroupId, assetId: assetId});

    return {
        ...data
    };
};

export default useFundFactSheet;