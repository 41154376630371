import React from "react";
import withProviders from "./components/hooks/withProviders";
import ProtectedRoute from "./components/ProtectedRoute";
import WebsiteLayout from "./components/WebsiteLayout";
import usePortfolioChanges, { PortfolioChangesProvider } from "./components/PortfolioChangesContext";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { WebsiteNavigationProvider } from "./components/WebsiteNavigationContext";

const App = () => {
    const navigate = useNavigate();
	const { changesCount, hasShownChanges, isLoading, setHasShownChanges } = usePortfolioChanges();

	React.useEffect(() => {
		if (changesCount && changesCount > 0 && !hasShownChanges) {
			setHasShownChanges(_ => true);
			sessionStorage.setItem("wp-changes-shown", true);
			navigate(`/dashboard/portfolio/changes`);
		}
	}, [ changesCount ]);

	return (
		<React.Fragment>
			<Helmet>
				<title>WealthPro - Dashboard</title>
			</Helmet>
			<ProtectedRoute loadOverride={isLoading}>
				<WebsiteNavigationProvider>
					<WebsiteLayout />
				</WebsiteNavigationProvider>
			</ProtectedRoute>
		</React.Fragment>
	);
};

export default withProviders(PortfolioChangesProvider)(App);