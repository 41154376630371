import { useGetHistoricalReturnQuery } from "../../../redux";

const usePerformanceHistoricalReturn = () => {
    const data = useGetHistoricalReturnQuery();

    return {
        ...data
    };
};

export default usePerformanceHistoricalReturn;