import React from "react";
import WebsiteHero from "../components/WebsiteHero";
import ResetPasswordRequestForm from "../components/forms/ResetPasswordRequestForm";

const ForgotPasswordPage = () => {
    return (
        <WebsiteHero>
            <ResetPasswordRequestForm />
        </WebsiteHero>
    );
};

export default ForgotPasswordPage;