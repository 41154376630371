import React from "react";
import { Disclosure, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Looks like an Accordion but has ZERO functionality of one!
export const StaticAccordion = ({ children, className, title, titleClassName }) => {
    return (
        <div>
            <div className={`flex items-center transition-all ease-linear w-full text-left p-5 border rounded rounded-b-none shadow-inner ${titleClassName ? titleClassName : 'bg-white border-slate-300'}`}>
                {title}
            </div>
            {children != null && children != 'undefined' && <div className={`bg-white w-full text-left border border-t-0 border-slate-300 rounded rounded-t-none shadow-inner ${className ? className : ''}`}>
                {children}
            </div>}
        </div>
    )
};

const Accordion = ({
    children,
    title,
    titleClassName,
    className
}) => {
    return (
        <Disclosure>
            <Disclosure.Button 
                className={`flex justify-between items-center transition-all ease-linear bg-white w-full text-left p-5 border border-slate-300 rounded shadow-inner ui-open:rounded-b-none ${titleClassName ? titleClassName : 'bg-white border-slate-300'}`}>
                {title}
                <FontAwesomeIcon icon="fa-chevron-right" className="transition-all duration-300 ui-open:rotate-90 ui-open:transform" />
            </Disclosure.Button>
            <Transition
                className="overflow-hidden"
                enter="transition-all ease-in-out duration-[900ms] delay-[200ms]"
                enterFrom="transform  max-h-0"
                enterTo="transform  max-h-fit"
                leave="transition-all ease-in-out duration-[600ms]"
                leaveFrom="transform  max-h-fit"
                leaveTo="transform  max-h-0"
            >
                <Disclosure.Panel className={`bg-white w-full text-left border border-t-0 border-slate-300 rounded rounded-t-none shadow-inner ${className ? className : ''}`}>
                    {children}
                </Disclosure.Panel>
            </Transition>
        </Disclosure>
    );
};

export default Accordion;

/*
enter="transition duration-200 ease-out"
                enterFrom="transform scale-100 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-200 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-100 opacity-0"
*/