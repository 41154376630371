import React, { useState } from "react";
import WealthProText from "../../components/WealthProText";
import useLogout from "../../components/hooks/useLogout";
import { useSession } from "../../components/SessionContext";
import { usePopper } from "react-popper";
import { Popover } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfileMenu = () => {
    const logout = useLogout();
    const [referenceElement, setReferenceElement] = useState();
    const [popperElement, setPopperElement] = useState();
    const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: "bottom-start" });
    const { user } = useSession();
    const { name, ref: master_account_reference } = user || { profile: { name: '', ref: '' } };

    return (
        <Popover>
            <Popover.Button ref={setReferenceElement} className="relative inline-flex justify-between items-center text-slate-600 p-3 mx-2">
                <div className="flex flex-col justify-start text-left min-w-[150px]">
                    <span className="text-sm">{name}</span>
                    <span className="text-xs text-slate-400">{master_account_reference}</span>
                </div>
                <FontAwesomeIcon icon="fa-chevron-right" size="xs" className="transition ease-in-out duration-500 ui-open:rotate-90 ui-open:transform" />
            </Popover.Button>
            <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                className="bg-white text-slate-700 text-sm shadow-inner w-72 rounded-md"
                {...attributes.popper}
            >
                {({ close }) => (
                    <React.Fragment>
                        {/* <div className="flex flex-col my-2 border-b border-slate-300">
                            <div className="px-3 py-2 text-xs uppercase text-slate-500">
                                <span>My Profile</span>
                            </div>
                            <div className="inline-flex items-center px-4 py-3 mb-2 cursor-pointer hover:bg-gray-200 hover:shadow-inner">
                                <FontAwesomeIcon icon="fa-cogs" className="text-red-400 me-2" />
                                <span>User Settings</span>
                            </div>
                        </div> */}
                        <div className="flex flex-col my-2">
                        <div className="px-3 py-2 text-xs uppercase text-slate-500">
                                <span>My Profile</span>
                            </div>
                            <div onClick={logout} className="inline-flex items-center px-4 py-3 mb-2 cursor-pointer hover:bg-gray-200 hover:shadow-inner">
                                <FontAwesomeIcon icon="fa-right-from-bracket" className="text-green-300 me-2" />
                                <span>Log Out</span>
                            </div>
                            <WealthProText className="px-4 py-2" />
                        </div>
                    </React.Fragment>
                )}
            </Popover.Panel>
        </Popover>  
    );
};

export default ProfileMenu;