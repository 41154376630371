import React from "react";
import LoginDisplayCard from "../components/LoginDisplayCard";
import WebsiteHero from "../components/WebsiteHero";

const HomePage = () => (
    <WebsiteHero>
        <LoginDisplayCard />
    </WebsiteHero>
);

export default HomePage;