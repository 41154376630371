import { useGetVolatilityStatisticsQuery } from "../../../redux";

const usePerformanceVolatilityStatistics = () => {
    
    const data = useGetVolatilityStatisticsQuery();

    return {
        ...data
    };
};

export default usePerformanceVolatilityStatistics;