import React from "react";
import { LinkButton } from "./Button";

const LoginWelcomeDisplay = ({ user }) => {
    const { name } = user;
    return (
        <React.Fragment>
            <div className="flex flex-col justify-center items-center mb-4">
                <h1 className="text-md text-gray-500">Welcome back, {name}</h1>
            </div>
            <LinkButton href="/dashboard">Continue to your Account</LinkButton>
        </React.Fragment>
    );
};

export default LoginWelcomeDisplay;