/// Generic Display Card for a List of information like products or designations
import React from "react";
import Money from "../../../components/Money";
import FetchErrorMessage from "../../../components/FetchErrorMessage";
import StandardTable from "../../../components/StandardTable";
import DisplayCard, { DisplayCardBody } from "../../../components/DisplayCard";

export const InvestmentProductsTableDisplayCard = ({ data = null, error = null, isLoading = false }) => {
    const { products, portfolioCurrencySymbol } = data || { products: [], portfolioCurrencySymbol: '£' };
    
    // if(products.length == 0){
    //     return 
    // }

    return (
        <TableDisplayCard 
            cardBackground="bg-white" 
            cardColor="text-red-700"
            currencySymbol={portfolioCurrencySymbol}
            columns={[
                { label: '', property: 'product' },
                { 
                    label: '', 
                    property: 'totalFloored',
                    renderer: ({ key, data }) => {
                        return (
                            <td key={key} className="text-right tabular-nums">
                                <Money prefix={portfolioCurrencySymbol} value={data.totalFloored} />
                            </td>
                        );
                    } 
                },
            ]}
            error={error}
            isLoading={isLoading}
            tableData={products}
            title="Investment Structures"
        />
    );
};

export const InvestmentOwnershipTableDisplayCard = ({ data = null, error = null, isLoading = false }) => {
    const { designations, portfolioCurrencySymbol } = data || { designations: [], portfolioCurrencySymbol: '£' };
    return (
        <TableDisplayCard 
            cardBackground="bg-white" 
            cardColor="text-red-500"
            currencySymbol={portfolioCurrencySymbol}
            columns={[
                { label: '', property: 'designation' },
                { 
                    label: '', 
                    property: 'totalFloored',
                    renderer: ({ key, data }) => {
                        return (
                            <td key={key} className="text-right tabular-nums">
                                <Money prefix={portfolioCurrencySymbol} value={data.totalFloored} />
                            </td>
                        );
                    } 
                },
            ]}
            error={error}
            isLoading={isLoading}
            tableData={designations}
            title="Investment Ownership"
        />
    );
};

const TableDisplayCard = ({
    cardBackground = "bg-white", 
    cardColor = "text-black", 
    currencySymbol = "£",
    columns = [],
    error = null, 
    isLoading = false,
    tableData = [], 
    title = "",
}) => {
    return (
        <DisplayCard className={`w-full shadow-inner border-slate-300 ${cardBackground} ${cardColor} ${isLoading === true ? 'animate-pulse h-48' : ''}`}>
            {isLoading === false && (
                <DisplayCardBody className="text-center">
                    <h2 className="card-title justify-center text-xl uppercase">{title}</h2>
                    {(error) && (
                        <React.Fragment>
                            <FetchErrorMessage />
                        </React.Fragment>
                    )}
                    {(!error && tableData.length === 0) && (
                        <React.Fragment>
                            <p>There are currently no funds held under this account.</p>
                        </React.Fragment>
                    )}
                    {!error && tableData.length > 0 && (
                        <StandardTable 
                            columns={columns}
                            data={tableData}
                            hideHeader={true}
                        />
                    )}
                </DisplayCardBody>
            )}
        </DisplayCard>
    );
};

export default TableDisplayCard;