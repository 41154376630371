import wealthProApi from "../api";

const fundsheetsEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        getFundFactSheetList: builder.query({
            query: () => `api/fundfactsheet/assetgrouplist`
        }),
        getFundFactSheet: builder.query({
            query: (body) => ({
                url: `api/fundfactsheet`,
                method: "POST",
                body
            })
        })
    })
});

export const {
    useGetFundFactSheetListQuery,
    useGetFundFactSheetQuery
} = fundsheetsEndpoints;